/*
 * @author Oleg Khalidov <brooth@gmail.com>.
 * -----------------------------------------------
 * Freelance software development:
 * Upwork: https://www.upwork.com/freelancers/~01d93e90d5b37c48d2
 */

.modal {
  pointer-events: none;
  background-color: black;
}

.modal > * {
  opacity: 0.8;
}

.modal > .loadingIcon {
  position: absolute; 
  left: 50%; 
  top: 50%; 
  margin-left: -10px; 
  margin-right: -10px; 
  opacity: 1;
}