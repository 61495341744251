@import url(https://cdnjs.cloudflare.com/ajax/libs/meyer-reset/2.0/reset.min.css);
/*
 * @author Oleg Khalidov <brooth@gmail.com>.
 * -----------------------------------------------
 * Freelance software development:
 * Upwork: https://www.upwork.com/freelancers/~01d93e90d5b37c48d2
 */
* {
  box-sizing: border-box;
}

/*
 * Circular Std
 */
@font-face {
  font-family: "Circular Std";
  src: url(/static/media/CircularStd-Book.90481370.otf) format("opentype");
}
@font-face {
  font-family: "Circular Std Black";
  src: url(/static/media/CircularStd-Black.757b8ac8.otf) format("opentype");
}
@font-face {
  font-family: "Circular Std Black-Italic";
  src: url(/static/media/CircularStd-BlackItalic.e1f39437.otf) format("opentype");
}
@font-face {
  font-family: "Circular Std Bold";
  src: url(/static/media/CircularStd-Bold.5c4514a8.otf) format("opentype");
}
@font-face {
  font-family: "Circular Std Bold-Italic";
  src: url(/static/media/CircularStd-BoldItalic.c1293725.otf) format("opentype");
}
@font-face {
  font-family: "Circular Std Book";
  src: url(/static/media/CircularStd-Book.90481370.otf) format("opentype");
}
@font-face {
  font-family: "Circular Std Book-Italic";
  src: url(/static/media/CircularStd-BookItalic.f8190a45.otf) format("opentype");
}
@font-face {
  font-family: "Circular Std Medium";
  src: url(/static/media/CircularStd-Medium.9ffafedd.otf) format("opentype");
}
@font-face {
  font-family: "Circular Std Medium-Italic";
  src: url(/static/media/CircularStd-MediumItalic.55bf23ce.otf) format("opentype");
}

/*
 * Poppins Font
 */
@font-face {
  font-family: "Poppins";
  src: url(/static/media/Poppins-Black.eacee5bb.ttf) format("opentype");
  font-weight: 900;
  font-style: normal;
}
@font-face {
  font-family: "Poppins";
  src: url(/static/media/Poppins-BlackItalic.9445d60e.ttf) format("opentype");
  font-weight: 900;
  font-style: italic;
}
@font-face {
  font-family: "Poppins";
  src: url(/static/media/Poppins-Bold.404e299b.ttf) format("opentype");
  font-weight: 700;
  font-style: normal;
}
@font-face {
  font-family: "Poppins";
  src: url(/static/media/Poppins-BoldItalic.b10e7a6e.ttf) format("opentype");
  font-weight: 700;
  font-style: italic;
}
@font-face {
  font-family: "Poppins";
  src: url(/static/media/Poppins-SemiBold.cce5625b.ttf) format("opentype");
  font-weight: 600;
  font-style: normal;
}
@font-face {
  font-family: "Poppins";
  src: url(/static/media/Poppins-SemiBoldItalic.cc7ee35c.ttf) format("opentype");
  font-weight: 600;
  font-style: italic;
}
@font-face {
  font-family: "Poppins";
  src: url(/static/media/Poppins-Medium.9e1bb626.ttf) format("opentype");
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: "Poppins";
  src: url(/static/media/Poppins-MediumItalic.4e8cea18.ttf) format("opentype");
  font-weight: 500;
  font-style: italic;
}
@font-face {
  font-family: "Poppin";
  src: url(/static/media/Poppins-Regular.8081832f.ttf) format("opentype");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "Poppins";
  src: url(/static/media/Poppins-Italic.ce3fff31.ttf) format("opentype");
  font-weight: normal;
  font-style: italic;
}
@font-face {
  font-family: "Poppin";
  src: url(/static/media/Poppins-Light.f41b63c0.ttf) format("opentype");
  font-weight: 300;
  font-style: normal;
}
@font-face {
  font-family: "Poppins";
  src: url(/static/media/Poppins-LightItalic.5ccd942a.ttf) format("opentype");
  font-weight: 300;
  font-style: italic;
}
@font-face {
  font-family: "Poppin";
  src: url(/static/media/Poppins-Thin.a61eaf4c.ttf) format("opentype");
  font-weight: 100;
  font-style: normal;
}
@font-face {
  font-family: "Poppins";
  src: url(/static/media/Poppins-ThinItalic.32743a4d.ttf) format("opentype");
  font-weight: 100;
  font-style: italic;
}

body {
  margin: 0;
  min-height: 100vh;
  font-family: "Circular Std", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
body:after {
  content: '';
  position: fixed;
  top: -50%;
  right: -50%;
  bottom: -50%;
  left: -50%;
  z-index: -1;
  background: white;
}

#root {
  min-width: 1200px;
}

:root {
  --eerie-black: rgba(32, 32, 32, 1);
  font-size: 3.8mm;
}

svg {
  overflow: visible;
}

.modal-backdrop.show {
  opacity: 0.75 !important;
}
.modal-dialog {
  max-width: 39.6471rem !important;
}
.modal-content {
  background-color: transparent !important;
  border: 0px !important;
  align-items: center;
}

/* Toast */
.Toastify__toast--warning {
  background-color: #f1aa0f;
}
.Toastify__toast--warning > .Toastify__toast-body{
  color: #fff;
}
.Toastify__toast--error > .Toastify__toast-body{
  color: #fdfdfd;
}

.badge {
  font-weight: normal;
}
/*
 * @author Oleg Khalidov <brooth@gmail.com>.
 * -----------------------------------------------
 * Freelance software development:
 * Upwork: https://www.upwork.com/freelancers/~01d93e90d5b37c48d2
 */
.styles_toastContainer__1IgwL {
  width: auto;
  min-width: 150px;
}
.styles_toast__2xSKG {
  font-family: Circular Std Medium;
  font-style: normal;
  font-weight: normal;
  font-size: 1rem;
  color: rgba(32, 32, 32, 1);
  border-radius: 99px;
  min-height: 30px;
}
.styles_toast__2xSKG [class="Toastify__toast-body"] {
  text-align: center;
}
.styles_toast__2xSKG [class*="Toastify__close-button "] {
  align-self: center;
  padding: 0px 8px 1px 8px;
}

.styles_toast__2xSKG[class*="Toastify__toast--success"] {
  background-color: rgba(6, 180, 81, 0.979);
  color: white;
}

.styles_toast__2xSKG[class*="Toastify__toast--info"] {
  background-color: rgba(6, 110, 180, 0.979);
  color: white;
}

.styles_loginConteiner__38tac {
  background-color: rgba(0, 0, 0, 0.1);
  background-blend-mode: multiply;
  background-image: url(/static/media/auth-bg.fd5477d5.png);
  background-size: cover;
  min-height: 72rem;
}

.styles_loginConteiner__38tac .styles_header__TuJvJ {
  background-color: #C6C9CD;
  border-bottom: 1px #d8dadd solid;
}

.styles_formBloc__jT6Tf {
  position: absolute;
  top: 8.6471rem;
  right: 8.3529rem;
  width: 39rem;
}

.styles_formTitle__2NkF_ {
  padding-top: 2.4444rem;
}

.styles_formSubtitle__2eXuX {
  padding-top: 0rem;
}

.styles_form__2cZGc {
  padding-left: 3.1111rem;
  padding-right: 3.1111rem;
  padding-top: 1.4111rem;
}

.styles_formInputField__2QjY1 {
  padding-top: 1.5rem;
}

.styles_formPasswordField__3vSNd>input {
  padding-right: 2.9rem;
}

.styles_passwordHideIcon__2NvGB {
  position: absolute;
  right: 4.3rem;
  margin-top: -2.36rem;
}

.styles_button__e_xAv {
  margin-top: 3rem;
  height: 3.5rem;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: linear-gradient(302.39deg, #084edd -18.61%, #489ff9 113.13%);
  border: 0px;
  border-radius: 1.7778rem;
  overflow: hidden;
}

.styles_button__e_xAv>span {
  color: white;
}

.styles_formCopyright__lbifB {
  opacity: 0.6;
  margin-top: 6.1111rem;
  margin-bottom: 1.6667rem;
  overflow: visible;
  white-space: nowrap;
  text-align: left;
  font-family: Circular Std Book;
  font-style: normal;
  font-weight: normal;
  font-size: 0.8889rem;
  color: rgba(28, 28, 28, 1);
}
/*
 * @author Oleg Khalidov <brooth@gmail.com>.
 * -----------------------------------------------
 * Freelance software development:
 * Upwork: https://www.upwork.com/freelancers/~01d93e90d5b37c48d2
 */
.styles_header__2Y8_6 {
  width: 100%;
  height: 5.5556rem;
  border-bottom: 1px #CACBCD solid;
  background-color: white;
  display: inline-flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 8.5rem;
  padding-right: 8.5rem;
}

.styles_header__2Y8_6 > img {
  height: 60px;
}

.styles_header__2Y8_6 > .styles_buttons__2Jp87 {
  display: inline-flex;
  justify-content: space-between;
  align-items: center;
}
.styles_header__2Y8_6 > .styles_buttons__2Jp87 > * {
  margin-left: 10px;
}
/*
 * @author Oleg Khalidov <brooth@gmail.com>.
 * -----------------------------------------------
 * Freelance software development:
 * Upwork: https://www.upwork.com/freelancers/~01d93e90d5b37c48d2
 */

.styles_button__biRUi {
  background-color: white;
  border-radius: 9999px;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  padding: 5px 17px;
  cursor: pointer;
}
.styles_button__biRUi:hover {
  opacity: 0.9;
}
.styles_button__biRUi:active {
  opacity: 1;
}
.styles_disabled__D46sq {
  cursor: none;
  pointer-events: none;
  opacity: 0.5;
}
.styles_loading__2Q282 {
  -webkit-animation: styles_waves__2w67l 60000s linear infinite forwards;
          animation: styles_waves__2w67l 60000s linear infinite forwards;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  cursor: none;
  pointer-events: none;
}

.styles_text__oW9xA {
  -webkit-user-select: none;
      -ms-user-select: none;
          user-select: none;
  color: var(--eerie-black-2);
  font-family: "Circular Std", Helvetica;
  font-size: 1.1111rem;
  font-style: normal;
  font-weight: normal;
  letter-spacing: 0px;
  margin-bottom: -1px;
  margin-left: 0.39rem;
  margin-right: 0.2778rem;
  white-space: nowrap;
}

@-webkit-keyframes styles_waves__2w67l {
  0% {
    background-position: 0vw 0
  }
  100% {
    background-position: 1000000vw 0
  }
}

@keyframes styles_waves__2w67l {
  0% {
    background-position: 0vw 0
  }
  100% {
    background-position: 1000000vw 0
  }
}
/*
 * @author Oleg Khalidov <brooth@gmail.com>.
 * -----------------------------------------------
 * Freelance software development:
 * Upwork: https://www.upwork.com/freelancers/~01d93e90d5b37c48d2
 */
.styles_iconButton__2Ha0B {
  cursor: pointer;
}

/*
 * @author Oleg Khalidov <brooth@gmail.com>.
 * -----------------------------------------------
 * Freelance software development:
 * Upwork: https://www.upwork.com/freelancers/~01d93e90d5b37c48d2
 */

.styles_svg__3KLB9 {
  fill: rgba(32, 32, 32, 1);
  margin-right: 5px;
}
.styles_path__3xUT5 {
  overflow: visible;
  position: absolute;
  width: 24px;
  height: 24px;
  left: 0px;
  top: 0px;
  -webkit-transform: matrix(1, 0, 0, 1, 0, 0);
          transform: matrix(1, 0, 0, 1, 0, 0);
}

/*
 * @author Oleg Khalidov <brooth@gmail.com>.
 * -----------------------------------------------
 * Freelance software development:
 * Upwork: https://www.upwork.com/freelancers/~01d93e90d5b37c48d2
 */

.styles_modal__3T38a {
  pointer-events: none;
  background-color: black;
}

.styles_modal__3T38a > * {
  opacity: 0.8;
}

.styles_modal__3T38a > .styles_loadingIcon__1fj-y {
  position: absolute; 
  left: 50%; 
  top: 50%; 
  margin-left: -10px; 
  margin-right: -10px; 
  opacity: 1;
}
/*
 * @author Oleg Khalidov <brooth@gmail.com>.
 * -----------------------------------------------
 * Freelance software development:
 * Upwork: https://www.upwork.com/freelancers/~01d93e90d5b37c48d2
 */

.styles_loadingIndicator__3bh52 {
  width: 20px;
  height: 20px;
  -webkit-animation: styles_rotation__wUtsT 2s infinite ease-in-out;
          animation: styles_rotation__wUtsT 2s infinite ease-in-out;
}

@-webkit-keyframes styles_rotation__wUtsT {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(359deg);
            transform: rotate(359deg);
  }
}

@keyframes styles_rotation__wUtsT {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(359deg);
            transform: rotate(359deg);
  }
}

/*
 * @author Oleg Khalidov <brooth@gmail.com>.
 * -----------------------------------------------
 * Freelance software development:
 * Upwork: https://www.upwork.com/freelancers/~01d93e90d5b37c48d2
 */

.styles_bloc__EzyJA {
  align-items: center;
  background-color: white;
  border-radius: 22px;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

/*
 * @author Oleg Khalidov <brooth@gmail.com>.
 * -----------------------------------------------
 * Freelance software development:
 * Upwork: https://www.upwork.com/freelancers/~01d93e90d5b37c48d2
 */
.styles_form__1z7on {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.styles_form__1z7on > form {
  width: 100%;
  padding-top: 0px;
}
.styles_error__29tbw {
  height: 1.1111rem;
  margin-top: 20px;
  margin-left: 1.1111rem;
  margin-right: 1.1111rem;
  text-align: center;
}

/*
 * @author Oleg Khalidov <brooth@gmail.com>.
 * -----------------------------------------------
 * Freelance software development:
 * Upwork: https://www.upwork.com/freelancers/~01d93e90d5b37c48d2
 */

.styles_error__2b2nz {
  font-family: Circular Std Book;
  font-size: 0.8889rem;
  color: rgb(212, 40, 40);
  text-align: left;
}

/*
 * @author Oleg Khalidov <brooth@gmail.com>.
 * -----------------------------------------------
 * Freelance software development:
 * Upwork: https://www.upwork.com/freelancers/~01d93e90d5b37c48d2
 */
.styles_formInputField__GX8Sr {
  width: 100%;
}

.styles_header__1B0LY {
  display: flex;
  justify-content: space-between;
  margin-bottom: 0.6111rem;
}

.styles_label__21A0x {
  opacity: 0.7;
  overflow: visible;
  white-space: nowrap;
  text-align: left;
  font-family: Circular Std Book;
  font-weight: normal;
  font-size: 0.8889rem;
  color: rgba(32, 32, 32, 1);
}

.styles_error__1z0oc {
  text-align: right;
  font-family: Circular Std Book;
  font-size: 0.8889rem;
  color: rgb(212, 40, 40);
}

.styles_control__2TN6D {
  padding: 1.7rem 1.3333rem;
  width: 100%;
  background-color: #f6f6f6;
  border: 0px;
  border-radius: 0.6667rem;
  white-space: nowrap;
  text-align: left;
  font-family: Circular Std;
  font-style: normal;
  font-weight: normal;
  font-size: 1rem;
  color: rgba(32, 32, 32, 1);
}

textarea.styles_control__2TN6D {
  padding-top: 1rem;
  white-space: normal;
}

select.styles_control__2TN6D,
select.styles_control__2TN6D ::before,
select.styles_control__2TN6D::after {
  box-sizing: border-box;
}
select.styles_control__2TN6D {
  -webkit-appearance: none;
          appearance: none;
}

/*
 * @author Oleg Khalidov <brooth@gmail.com>.
 * -----------------------------------------------
 * Freelance software development:
 * Upwork: https://www.upwork.com/freelancers/~01d93e90d5b37c48d2
 */

.styles_title__vOHWk {
  letter-spacing: 0rem;
  line-height: 3.2222rem;
  min-height: 2.4444rem;
  min-width: 16rem;
  white-space: nowrap;
  color: var(--eerie-black);
  font-family: "CircularStd Black-Regular", Helvetica;
  font-size: 2.3333rem;
  font-style: normal;
  font-weight: 400;
}

/*
 * @author Oleg Khalidov <brooth@gmail.com>.
 * -----------------------------------------------
 * Freelance software development:
 * Upwork: https://www.upwork.com/freelancers/~01d93e90d5b37c48d2
 */

.styles_subtitle__3ftx8 {
  color: var(--eerie-black);
  font-family: "CircularStd Book-Regular", Helvetica;
  font-size: 1.3333rem;
  font-style: normal;
  font-weight: 400;
  letter-spacing: 0rem;
  line-height: 2.8889rem;
  min-height: 1.4444rem;
  min-width: 23.7778rem;
  opacity: 0.7;
  text-align: center;
  white-space: nowrap;
}

/*
 * @author Oleg Khalidov <brooth@gmail.com>.
 * -----------------------------------------------
 * Freelance software development:
 * Upwork: https://www.upwork.com/freelancers/~01d93e90d5b37c48d2
 */

.styles_formImage__2uUyB {
  width:  11.5477rem;
  height: 10.3074rem;
  margin-left: 12.3333rem;
  margin-right: 12.9523rem;
  margin-top: 4rem;
  overflow: visible;
}

/*
 * @author Oleg Khalidov <brooth@gmail.com>.
 * -----------------------------------------------
 * Freelance software development:
 * Upwork: https://www.upwork.com/freelancers/~01d93e90d5b37c48d2
 */

.styles_backLoadingIndicator__2TI9b {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

/*
 * @author Oleg Khalidov <brooth@gmail.com>.
 * -----------------------------------------------
 * Freelance software development:
 * Upwork: https://www.upwork.com/freelancers/~01d93e90d5b37c48d2
 */

.styles_backPrint__3RITs {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.styles_backPrint__3RITs > [class*="title"] {
  font-family: Circular Std Bold;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  color: rgba(125, 125, 125, 1);
}

.styles_backPrint__3RITs > [class*="message"] {
  font-family: Circular Std;
  font-style: normal;
  font-weight: normal;
  font-size: 17px;
  color: rgba(125, 125, 125, 1);
  margin-top: 20px;
}

.styles_backPrint__3RITs > [class*="button"] {
  margin-top: 30px;
  border: 1px solid rgb(255, 186, 71);
  color:  rgb(255, 186, 71);
  padding: 8px 12px;
  font-size: 15px;
}
/*
 * @author Oleg Khalidov <brooth@gmail.com>.
 * -----------------------------------------------
 * Freelance software development:
 * Upwork: https://www.upwork.com/freelancers/~01d93e90d5b37c48d2
 */

.styles_container__2LDyC {
  width: 39.6471rem;
  padding: 3.3rem 2.7rem 2.7rem 2.7rem;
}

.styles_container__2LDyC .styles_header__1PSQW {
  width: 100%;
  height: 1.8824rem;
  display: inline-flex;
  justify-content: space-between;
  align-items: center;
}

.styles_container__2LDyC .styles_header__1PSQW > span {
  font-family: Circular Std;
  font-style: normal;
  font-weight: normal;
  font-size: 1.8824rem;
  color: rgba(32, 32, 32, 1);
}

.styles_form__3_v4o {
  padding-top: 0rem;
}

.styles_form__3_v4o > form > * {
  margin-top: 1.5rem;
}

.styles_form__3_v4o > form > .styles_formRow__3fpeJ {
  margin-top: 10px;
}
.styles_form__3_v4o > form > .styles_formRow__3fpeJ > div {
  width: auto;
  flex: 1 1;
  margin: 10px 15px 0px 15px;
}
.styles_form__3_v4o > form > .styles_formRow__3fpeJ > div > input {
  padding: 1.5rem 1rem;
}
.styles_form__3_v4o > form > div > textarea {
  overflow-x: scroll;
  white-space: pre;
}

.styles_info__3CuxM {
  width: 100%;
  height: 3.5294rem;
  display: inline-flex;
  align-items: center;
  background-color: #f6f6f6;
  border-radius: 12px;
  border: 1px solid #e4e4e4;
  padding-left: 1.1rem;
  padding-right: 1.1rem;
}
.styles_info__3CuxM > span {
  padding-left: 0.8rem;
  font-family: Circular Std Book;
  font-style: normal;
  font-weight: normal;
  font-size: 0.8824rem;
  color: rgba(72, 72, 72, 1);
}

.styles_form__3_v4o > form > .styles_saveButton__1d5Jm {
  margin-top: 3rem;
  height: 3.5rem;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: linear-gradient(302.39deg, #084edd -18.61%, #489ff9 113.13%);
  border: 0px;
  border-radius: 1.7778rem;
  overflow: hidden;
}
.styles_saveButton__1d5Jm > span {
  color: white;
}
/*
 * @author Oleg Khalidov <brooth@gmail.com>.
 * -----------------------------------------------
 * Freelance software development:
 * Upwork: https://www.upwork.com/freelancers/~01d93e90d5b37c48d2
 */

.styles_container__12Ae5 {
  width: 39.6471rem;
  padding: 3.3rem 2.7rem 2.7rem 2.7rem;
}

.styles_container__12Ae5 .styles_header__1djsJ {
  width: 100%;
  height: 1.8824rem;
  display: inline-flex;
  justify-content: space-between;
  align-items: center;
}

.styles_container__12Ae5 .styles_header__1djsJ > span {
  font-family: Circular Std;
  font-style: normal;
  font-weight: normal;
  font-size: 1.8824rem;
  color: rgba(32, 32, 32, 1);
}

.styles_form__35eTI {
  padding-top: 0rem;
}

.styles_form__35eTI > form > * {
  margin-top: 1.5rem;
}

.styles_form__35eTI > form > .styles_formRow__zH3Gn {
  margin-top: 10px;
}
.styles_form__35eTI > form > .styles_formRow__zH3Gn > div {
  width: auto;
  flex: 1 1;
  margin: 10px 15px 0px 15px;
}
.styles_form__35eTI > form > .styles_formRow__zH3Gn > div > input {
  padding: 1.5rem 1rem;
}
.styles_form__35eTI > form > div > textarea {
  overflow-x: scroll;
  white-space: pre;
}

.styles_form__35eTI > form > .styles_taglist__2UaEJ > div[class*="react-tagsinput"] {
  margin-top: 0.6rem;
  padding: 0.7rem 1rem 0.4rem 1rem;
  width: 100%;
  background-color: #f6f6f6;
  border: 0px;
  border-radius: 0.6667rem;
}

.styles_form__35eTI
  > form
  > .styles_taglist__2UaEJ
  > div[class*="react-tagsinput"]
  input[class="react-tagsinput-input"] {
  width: 160px;
}

.styles_form__35eTI
  > form
  > .styles_taglist__2UaEJ
  > div[class*="react-tagsinput"]
  span[class="react-tagsinput-tag"] {
  white-space: nowrap;
  text-align: left;
  font-family: Circular Std;
  font-style: normal;
  font-weight: normal;
  font-size: 1rem;
  color: white;
  background-color: rgb(32, 32, 32, 1);
  border: 1px solid rgb(32, 32, 32, 1);
  border-radius: 5px;
}

.styles_form__35eTI
  > form
  > .styles_taglist__2UaEJ
  > div[class*="react-tagsinput"]
  a[class="react-tagsinput-remove"] {
  color: white;
}

.styles_form__35eTI > form > .styles_taglist__2UaEJ > span[class="label"] {
  opacity: 0.7;
  overflow: visible;
  white-space: nowrap;
  text-align: left;
  font-family: Circular Std Book;
  font-weight: normal;
  font-size: 0.8889rem;
  color: rgba(32, 32, 32, 1);
}

.styles_info__346m5 {
  width: 100%;
  height: 3.5294rem;
  display: inline-flex;
  align-items: center;
  background-color: #f6f6f6;
  border-radius: 12px;
  border: 1px solid #e4e4e4;
  padding-left: 1.1rem;
  padding-right: 1.1rem;
}
.styles_info__346m5 > span {
  padding-left: 0.8rem;
  font-family: Circular Std Book;
  font-style: normal;
  font-weight: normal;
  font-size: 0.8824rem;
  color: rgba(72, 72, 72, 1);
}

.styles_form__35eTI > form > .styles_saveButton__3WDQZ {
  margin-top: 3rem;
  height: 3.5rem;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: linear-gradient(302.39deg, #084edd -18.61%, #489ff9 113.13%);
  border: 0px;
  border-radius: 1.7778rem;
  overflow: hidden;
}
.styles_saveButton__3WDQZ > span {
  color: white;
}
/*
 * @author Oleg Khalidov <brooth@gmail.com>.
 * -----------------------------------------------
 * Freelance software development:
 * Upwork: https://www.upwork.com/freelancers/~01d93e90d5b37c48d2
 */

.styles_bloc__cDVni {
  width: 39.6471rem;
  padding: 3.3rem 2.7rem 2.7rem 2.7rem;
}

.styles_bloc__cDVni .styles_header__3Si3O {
  width: 100%;
  height: 1.8824rem;
  display: inline-flex;
  justify-content: space-between;
  align-items: center;
}

.styles_bloc__cDVni .styles_header__3Si3O>span {
  font-family: Circular Std;
  font-style: normal;
  font-weight: normal;
  font-size: 1.8824rem;
  color: rgba(32, 32, 32, 1);
}

.styles_caruselDots__2E41Y {
  margin: 40px 40px 0px 40px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  flex-wrap: wrap;
}

.styles_caruselDot__2o7g3 {
  margin: 0px 5px 5px 0px;
  width: 24px;
  height: 24px;
  border: 1px solid #d5d5d5;
  border-radius: 99px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.styles_caruselDotActive__1c_RU {
  border: 1px solid #a7b6ef;
  background-color: #e7ebfe;
}

.styles_dropzone__1S8aX {
  height: 605px;
  width: 100%;
  border: 3px dashed #b4b4b4;
  border-radius: 20px;
  margin: 30px 0px;
  padding: 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.styles_dropzone__1S8aX>p {
  margin-top: 25px;
  font-family: Circular Std;
  font-style: normal;
  font-weight: normal;
  font-size: 25px;
  color: #9d9b9b;
}

.styles_form__2zSxs {
  padding-top: 0rem;
  margin-bottom: 2rem;
}

.styles_form__2zSxs[class~="visible"] {
  display: flex;
}

.styles_form__2zSxs[class~="hidden"] {
  display: none;
}

.styles_form__2zSxs>form>* {
  margin-top: 1.5rem;
}

.styles_form__2zSxs>form>.styles_destinations__2pz8s {
  height: 220px;
  margin-bottom: 40px;
}

.styles_form__2zSxs>form>.styles_destinations__2pz8s>[name="destinations"] {
  height: 100%;
  padding: 0px;
  font-size: 13px;
  overflow: scroll;
}

.styles_form__2zSxs>form>.styles_destinations__2pz8s>textarea[name="destinations"] {
  padding: 10px 15px;
  line-height: 27.5px;
}

.styles_sendStateItem__2jMA4 {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 4px 15px;
}

.styles_sendStateItem__2jMA4:first-child {
  margin-top: 15px;
}

.styles_sendStateItem__2jMA4:last-child {
  margin-bottom: 15px;
}

.styles_sendStateItemError__17b3C {
  background-color: rgba(255, 0, 0, 0.11);
}

.styles_sendStateItem__2jMA4 span:first-child {
  margin-right: 50px;
}

.styles_sendStateItem__2jMA4 span:last-child {
  white-space: normal;
  text-align: right;
}

.styles_form__2zSxs>form>.styles_dateTimePicker__2Ikcd div[class='rdtPicker'] {
  bottom: 50px;
  left: 110px;
  box-shadow: 3px 1px 4px rgb(0 0 0 / 10%);
}

.styles_sendButton__1K23q {
  margin-top: 0.8rem;
  height: 3.5rem;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: linear-gradient(302.39deg, #084edd -18.61%, #489ff9 113.13%);
  border: 0px;
  border-radius: 1.7778rem;
  overflow: hidden;
}

.styles_sendButton__1K23q>span {
  color: white;
}
/*
 * @author Oleg Khalidov <brooth@gmail.com>.
 * -----------------------------------------------
 * Freelance software development:
 * Upwork: https://www.upwork.com/freelancers/~01d93e90d5b37c48d2
 */
.styles_formInputField__3vsRF {
  width: 100%;
}

.styles_header__36DBZ {
  display: flex;
  justify-content: space-between;
  margin-bottom: 0.6111rem;
}

.styles_label__2fo3W {
  opacity: 0.7;
  overflow: visible;
  white-space: nowrap;
  text-align: left;
  font-family: Circular Std Book;
  font-weight: normal;
  font-size: 0.8889rem;
  color: rgba(32, 32, 32, 1);
}

.styles_error__1jkKh {
  text-align: right;
  font-family: Circular Std Book;
  font-size: 0.8889rem;
  color: rgb(212, 40, 40);
}

.styles_control__1WTCR {
  padding: 0px;
  width: 100%;
  background-color: #f6f6f6;
  border: 0px;
  border-radius: 0.6667rem;
  white-space: nowrap;
  text-align: left;
  font-family: Circular Std;
  font-style: normal;
  font-weight: normal;
  font-size: 1rem;
  color: rgba(32, 32, 32, 1);
}

.styles_control__1WTCR input::file-selector-button {
  white-space: nowrap;
  text-align: left;
  font-family: Circular Std;
  font-style: normal;
  font-weight: normal;
  font-size: 1rem;
  color: white;
  background-color: rgb(53, 100, 187);
  border: 1px solid rgb(30, 88, 128);
  border-radius: 5px;
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
  padding: 14px 10px;
  margin-right: 10px;
}
/*
 * @author Oleg Khalidov <brooth@gmail.com>.
 * -----------------------------------------------
 * Freelance software development:
 * Upwork: https://www.upwork.com/freelancers/~01d93e90d5b37c48d2
 */
 .styles_formSelectField__1PtSd {
  width: 100%;
}

.styles_header__q4hF5 {
  display: flex;
  justify-content: space-between;
  margin-bottom: 0.6111rem;
}

.styles_label__DOXkp {
  opacity: 0.7;
  overflow: visible;
  white-space: nowrap;
  text-align: left;
  font-family: Circular Std Book;
  font-weight: normal;
  font-size: 0.8889rem;
  color: rgba(32, 32, 32, 1);
}

.styles_error__1-2Hm {
  text-align: right;
  font-family: Circular Std Book;
  font-size: 0.8889rem;
  color: rgb(212, 40, 40);
}

.styles_control__2wdxb {
  height: 3.4rem;
  padding: 0rem 2.9rem 0rem 1.4rem;
  width: 100%;
  background: none;
  background-color: #f6f6f6;
  border: 0px;
  border-radius: 0.6667rem;
  white-space: nowrap;
  text-align: left;
  font-family: Circular Std;
  font-style: normal;
  font-weight: normal;
  font-size: 1rem;
  color: rgba(32, 32, 32, 1);
  box-sizing: border-box;
}
.styles_control__2wdxb:invalid {
  color: #7E868D;
}

.styles_arrowIcon__3wk8C {
  position: relative;
  top: -1.9rem;
  right: 1.2rem;
  float: right;
  pointer-events: none;
}

/*
 * @author Oleg Khalidov <brooth@gmail.com>.
 * -----------------------------------------------
 * Freelance software development:
 * Upwork: https://www.upwork.com/freelancers/~01d93e90d5b37c48d2
 */
.styles_container__MpyMo {
  width: 280px;
}

.styles_header__2SYrZ {
  display: flex;
  justify-content: space-between;
  margin-bottom: 0.6111rem;
}

.styles_label__HfDvv {
  opacity: 0.7;
  overflow: visible;
  white-space: nowrap;
  text-align: left;
  font-family: Poppins;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 26px;
  letter-spacing: 0.04em;
  color: #202020;
}

.styles_error__xJOh0 {
  text-align: right;
  font-family: Circular Std Book;
  font-size: 0.8889rem;
  color: rgb(212, 40, 40);
}

.styles_control__2O5Hx {
  height: 3.4rem;
  width: 100%;
  background: none;
  background-color: #f6f6f6;
  border: 1px solid #d1d1d1;
  border-radius: 0.6667rem;
  white-space: nowrap;
  text-align: left;
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 24px;
  letter-spacing: 0.02em;
  color: rgba(32, 32, 32, 1);
  box-sizing: border-box;
  outline: none;
}
.styles_control__2O5Hx:invalid {
  color: #7e868d;
}

.styles_control__2O5Hx div[class*="-control"] {
  border: none;
  padding: 6px;
  background: none;
  outline: none;
}

.styles_control__2O5Hx div[class*="-control"] span[class*="-indicatorSeparator"] {
  display: none;
}

.styles_control__2O5Hx div[class*="-menu"] {
  border: 1px solid #d1d1d1;
  border-radius: 0.6667rem;
}
.styles_control__2O5Hx div[class*="-menu"] div[class*="-option"] {
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 24px;
  letter-spacing: 0.02em;
  /* color: #777777; */
}

/*
 * @author Oleg Khalidov <brooth@gmail.com>.
 * -----------------------------------------------
 * Freelance software development:
 * Upwork: https://www.upwork.com/freelancers/~01d93e90d5b37c48d2
 */
.styles_component__2hlx4 {
  width: 100%;
}

.styles_header__22Dmr {
  display: flex;
  justify-content: space-between;
  margin-bottom: 0.6111rem;
}

.styles_label__1nnfd {
  opacity: 0.7;
  overflow: visible;
  white-space: nowrap;
  text-align: left;
  font-family: Circular Std Book;
  font-weight: normal;
  font-size: 0.8889rem;
  color: rgba(32, 32, 32, 1);
}

.styles_error__3OAM3 {
  text-align: right;
  font-family: Circular Std Book;
  font-size: 0.8889rem;
  color: rgb(212, 40, 40);
}

.styles_control__2Fj6O {
  font-family: Circular Std Book;
  font-size: 0.8889rem;
  color: rgba(32, 32, 32, 1);
}
.styles_control__2Fj6O > input {
  padding: 1.7rem 1.3333rem;
  width: 100%;
  background-color: #f6f6f6;
  border: 0px;
  border-radius: 0.6667rem;
  white-space: nowrap;
  text-align: left;
  font-family: Circular Std;
  font-style: normal;
  font-weight: normal;
  font-size: 1rem;
  color: rgba(32, 32, 32, 1);
}
.styles_control__2Fj6O > input[readonly] {
  background-color: #f6f6f6;
}
.styles_control__2Fj6O > input[disabled] {
  background-color: #e9ecef;
  color: #6c757d;
}
.styles_control__2Fj6O > input[disabled]::-webkit-input-placeholder {
  color: #6c757d;
}
.styles_control__2Fj6O > input[disabled]:-ms-input-placeholder {
  color: #6c757d;
}
.styles_control__2Fj6O > input[disabled]::placeholder {
  color: #6c757d;
}

.styles_control__2Fj6O th[class="rdtPrev"],
.styles_control__2Fj6O th[class="rdtSwitch"],
.styles_control__2Fj6O th[class="rdtNext"] {
  padding: 5px;
  border-bottom: 1px solid #e5e5e5;
}
.styles_control__2Fj6O th[class="dow"] {
  margin-top: 5px;
  height: 25px;
  padding: 5px;
  border-radius: 0px;
  border: 0px none;
  border-top: 5px solid white;
}
.styles_control__2Fj6O td[class*="rdtDay"] {
  height: 25px;
  padding: 5px;
  border-radius: 0px;
  border: 0px none;
  text-align: center;
}
.styles_control__2Fj6O td[class*="rdtDay"]:nth-child(3) {
  font-weight: normal;
}

.styles_control__2Fj6O td[class*="rdtMonth"],
.styles_control__2Fj6O td[class*="rdtYear"] {
  padding: 5px;
  border-radius: 0px;
  border: 0px none;
  border-top: 5px solid white;
}

.styles_control__2Fj6O td[class*="rdtSwitch"] {
  cursor: pointer;
  padding: 7px;
  border: 1px solid #e5e5e5;
  border-radius: 10px;
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 26px;
  letter-spacing: 0.04em;
  color: #777777;
}
.styles_control__2Fj6O div[class="rdtTime"] table > tbody > tr > td {
  border: 0px none;
}

.styles_control__2Fj6O div[class*="rdtCounters"] {
  width: 100%;
  display: flex;
  justify-content: center;
}

.styles_control__2Fj6O *[class*="rdtCount"],
.styles_control__2Fj6O *[class*="rdtBtn"] {
  color: #646262;
  font-family: Circular Std;
  font-style: normal;
  font-weight: normal;
  font-size: 1rem;
  text-align: center;
}

.styles_resetButton__2JA9L {
  position: absolute;
  right: 3.7rem;
  margin-top: -2.2rem;
}

/*
 * @author Oleg Khalidov <brooth@gmail.com>.
 * -----------------------------------------------
 * Freelance software development:
 * Upwork: https://www.upwork.com/freelancers/~01d93e90d5b37c48d2
 */
 
.styles_tagsinput__U80F8 > div {
  width: 100%;
}

.styles_header__2ZXrf {
  display: flex;
  justify-content: space-between;
  margin-bottom: 0.6111rem;
}
 
.styles_label__1psDM {
  opacity: 0.7;
  overflow: visible;
  white-space: nowrap;
  text-align: left;
  font-family: Circular Std Book;
  font-weight: normal;
  font-size: 0.8889rem;
  color: rgba(32, 32, 32, 1);
}

.styles_error__2BCDm {
  text-align: right;
  font-family: Circular Std Book;
  font-size: 0.8889rem;
  color: rgb(212, 40, 40);
}

.styles_tagsinput__U80F8 > div[class*="react-tagsinput"] {
  margin-top: 0.6rem;
  padding: 0.7rem 1rem 0.4rem 1rem;
  width: 100%;
  background-color: #f6f6f6;
  border: 0px;
  border-radius: 0.6667rem;
}

.styles_tagsinput__U80F8
  > div[class*="react-tagsinput"]
  input[class="react-tagsinput-input"] {
  width: 180px;
}

.styles_tagsinput__U80F8
  > div[class*="react-tagsinput"]
  span[class="react-tagsinput-tag"] {
  white-space: nowrap;
  text-align: left;
  font-family: Circular Std;
  font-style: normal;
  font-weight: normal;
  font-size: 1rem;
  color: white;
  background-color: rgb(53, 100, 187);
  border: 1px solid rgb(30, 88, 128);
  border-radius: 5px;
}

.styles_tagsinput__U80F8
  > div[class*="react-tagsinput"]
  a[class="react-tagsinput-remove"] {
  color: white;
}

.styles_tagsinput__U80F8 > span[class="label"] {
  opacity: 0.7;
  overflow: visible;
  white-space: nowrap;
  text-align: left;
  font-family: Circular Std Book;
  font-weight: normal;
  font-size: 0.8889rem;
  color: rgba(32, 32, 32, 1);
}
/*
 * @author Oleg Khalidov <brooth@gmail.com>.
 * -----------------------------------------------
 * Freelance software development:
 * Upwork: https://www.upwork.com/freelancers/~01d93e90d5b37c48d2
 */

.styles_clientBloc__3UXDp {
  width: 39.6471rem;
  padding: 3.3rem 2.7rem 2.7rem 2.7rem;
}

.styles_clientBloc__3UXDp .styles_header__3SeXx {
  width: 100%;
  height: 1.8824rem;
  display: inline-flex;
  justify-content: space-between;
  align-items: center;
}

.styles_clientBloc__3UXDp .styles_header__3SeXx > span {
  font-family: Circular Std;
  font-style: normal;
  font-weight: normal;
  font-size: 1.8824rem;
  color: rgba(32, 32, 32, 1);
}

.styles_form__39p3q {
  padding-top: 1.7647rem;
}

.styles_form__39p3q > form > * {
  margin-top: 1.5rem;
}

.styles_info__20aGV {
  width: 100%;
  height: 3.5294rem;
  display: inline-flex;
  align-items: center;
  background-color: #f6f6f6;
  border-radius: 12px;
  border: 1px solid #e4e4e4;
  padding-left: 1.1rem;
  padding-right: 1.1rem;
}
.styles_info__20aGV > span {
  padding-left: 0.8rem;
  font-family: Circular Std Book;
  font-style: normal;
  font-weight: normal;
  font-size: 0.8824rem;
  color: rgba(72, 72, 72, 1);
}

.styles_formPasswordField__1xgga > input {
  padding-right: 3.2rem;
}

.styles_passwordHideIcon__guQtV {
  position: absolute;
  right: 4rem;
  margin-top: -2.36rem;
}

.styles_form__39p3q > form > .styles_saveButton__1HYwH {
  margin-top: 3rem;
  height: 3.5rem;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: linear-gradient(302.39deg, #084edd -18.61%, #489ff9 113.13%);
  border: 0px;
  border-radius: 1.7778rem;
  overflow: hidden;
}
.styles_saveButton__1HYwH > span {
  color: white;
}
/*
 * @author Oleg Khalidov <brooth@gmail.com>.
 * -----------------------------------------------
 * Freelance software development:
 * Upwork: https://www.upwork.com/freelancers/~01d93e90d5b37c48d2
 */

.styles_list__2pxaJ {
  width: 100%;
  margin-top: 1.9rem;
}

.styles_list__2pxaJ > .styles_row__tiPf1 > div {
  margin-bottom: 1.9rem;
}

.styles_addClientButton__abhap {
  margin-left: 1.8rem;
  width: 10.1176rem;
  height: 42px;
  background-color: black;
}
.styles_addClientButton__abhap:hover {
  background-color: rgba(0, 0, 0, 0.678);
}
.styles_addClientButton__abhap > span {
  color: white;
}

/*
 * @author Oleg Khalidov <brooth@gmail.com>.
 * -----------------------------------------------
 * Freelance software development:
 * Upwork: https://www.upwork.com/freelancers/~01d93e90d5b37c48d2
 */

.styles_modal__2yV8j {
  background-color: transparent;
  border: 0px;
}

.styles_dialog__N9RpV {
  background-color: white;
  padding: 2rem 1.6rem 1.6rem 1.6rem;
  width: auto;
  min-height: 11.1rem;
  border: 1px solid #d1d1d1;
  border-radius: 1.2941rem;
  display: flex;
  flex-direction: column;
  width: 23.5294rem;
}

.styles_dialog__N9RpV > .styles_content__ny-7_ {
  flex: 4 1;
  display: flex;
  flex-direction: column;
}
.styles_dialog__N9RpV > .styles_content__ny-7_ [class*="title"] {
  text-align: left;
  font-family: Circular Std Bold;
  font-style: normal;
  font-weight: normal;
  font-size: 1.4118rem;
  color: rgba(32, 32, 32, 1);
}
.styles_dialog__N9RpV > .styles_content__ny-7_ [class*="message"] {
  text-align: left;
  font-family: Circular Std;
  font-style: normal;
  font-weight: normal;
  font-size: 1.1rem;
  color: rgba(32, 32, 32, 1);
  margin-top: 10px;
}
.styles_dialog__N9RpV > .styles_content__ny-7_ [class*="error"] {
  text-align: left;
  font-family: Circular Std;
  font-style: normal;
  font-weight: normal;
  font-size: 0.9rem;
  color: #df2c2c;
  margin-top: 10px;
}
.styles_dialog__N9RpV > .styles_content__ny-7_ [class*="icon"] {
  margin: 0.13rem 0.7rem 0px 0.3rem;
}

.styles_dialog__N9RpV > .styles_buttons__3aeN1 {
  flex: 1 1;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  height: 45px;
}
.styles_dialog__N9RpV > .styles_buttons__3aeN1 span {
  font-size: 0.9rem;
}

.styles_dialog__N9RpV > .styles_buttons__3aeN1 > div {
  border: 1px solid #d1d1d1;
  flex: 1 1;
}
.styles_dialog__N9RpV > .styles_buttons__3aeN1 > div:nth-child(1) {
  margin-right: 0.7rem;
}
.styles_dialog__N9RpV > .styles_buttons__3aeN1 > div:nth-child(2) {
  margin-left: 0.7rem;
}

.styles_dialog__N9RpV > .styles_buttons__3aeN1 [class*="danger"]  {
  color: #df2c2c;
  border: 1px solid #f8cece;
  background: linear-gradient(302.39deg, #fceded -18.61%, rgb(250, 212, 212) 113.13%);
}

/*
 * @author Oleg Khalidov <brooth@gmail.com>.
 * -----------------------------------------------
 * Freelance software development:
 * Upwork: https://www.upwork.com/freelancers/~01d93e90d5b37c48d2
 */

.styles_listItem__3AX9T {
  padding: 2rem 1.6rem 1.6rem 1.6rem;
  width: 100%;
  height: 100%;
  min-height: 11.1rem;
  border: 1px solid #d1d1d1;
  border-radius: 1.2941rem;
  display: flex;
  flex-direction: column;
}

.styles_listItem__3AX9T>.styles_content__2Y5p_ {
  flex-grow: 1;
  padding-bottom: 1.7rem;
  width: 100%;
}

.styles_listItem__3AX9T>.styles_content__2Y5p_ [class="icon"] {
  margin: 0.1rem 0.5rem 0px 0rem;
}

.styles_listItem__3AX9T>.styles_content__2Y5p_ [class="title"] {
  text-align: left;
  font-family: Circular Std Bold;
  font-style: normal;
  font-weight: normal;
  font-size: 1.3rem;
  line-height: 1.45rem;
  color: rgba(32, 32, 32, 1);
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow-x: hidden;
}

.styles_listItem__3AX9T>.styles_content__2Y5p_ [class="subtitle"] {
  text-align: left;
  font-family: Circular Std Book;
  font-style: normal;
  font-weight: normal;
  font-size: 0.9rem;
  color: #7d7d7d;
  letter-spacing: 0.1px;
  padding-top: 10px;
}

.styles_listItem__3AX9T>.styles_buttons__3XfnL {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  height: 2.3rem;
}

.styles_listItem__3AX9T>.styles_buttons__3XfnL span {
  font-size: 0.9rem;
}

.styles_listItem__3AX9T>.styles_buttons__3XfnL>.styles_edit__1UiC2 {
  border: 1px solid #d1d1d1;
  flex: 1 1;
  margin-right: 0.7rem;
}

.styles_listItem__3AX9T>.styles_buttons__3XfnL>.styles_remove__3sHmk {
  margin-left: 0.7rem;
  border: 1px solid #f8cece;
  color: #df2c2c;
  background-color: #fceded;
  flex: 1 1;
}
/*
 * @author Oleg Khalidov <brooth@gmail.com>.
 * -----------------------------------------------
 * Freelance software development:
 * Upwork: https://www.upwork.com/freelancers/~01d93e90d5b37c48d2
 */

.styles_searchResult__2vfCP {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  background-color: #f5f6f9;
  padding: 1.1765rem 1.8824rem;
  border-radius: 16px;
  border: 0.1px solid #c5ccdc;
  margin-top: 2rem;
  margin-bottom: 2rem;
  align-self: center;
}
.styles_searchResult__2vfCP > span {
  font-family: Circular Std Book;
  font-style: normal;
  font-weight: normal;
  font-size: 1.0588rem;
  color: rgba(32, 32, 32, 1);
}

/*
 * @author Oleg Khalidov <brooth@gmail.com>.
 * -----------------------------------------------
 * Freelance software development:
 * Upwork: https://www.upwork.com/freelancers/~01d93e90d5b37c48d2
 */

.styles_header__1dyQm {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 2.5rem;
}
.styles_header__1dyQm > .styles_title__4ePXC {
  font-family: Circular Std Bold;
  font-style: normal;
  font-weight: normal;
  font-size: 2.8889rem;
  color: rgba(32, 32, 32, 1);
  margin-right: 2.3rem;
}
.styles_header__1dyQm > .styles_searchingFor__-MK38 {
  display: inline-flex;
  align-items: center;
  margin-bottom: 2.3rem;
}
.styles_header__1dyQm > .styles_searchingFor__-MK38 > span {
  font-family: Circular Std Black;
  font-style: normal;
  font-weight: normal;
  font-size: 1.8824rem;
  color: rgba(32, 32, 32, 1);
  margin-left: 1.2rem;
}

.styles_addLocationButton__3Hx3d {
  margin-left: 4rem;
  width: 11.5882rem;
  height: 3.1111rem;
  background-color: #00c455;
}
.styles_addLocationButton__3Hx3d:hover {
  background-color: #00c455cb;
}
.styles_addLocationButton__3Hx3d:active {
  background-color: #00c455;
}
.styles_addLocationButton__3Hx3d > span {
  color: white;
}

.styles_addClientButton__21bMB {
  margin-left: 1.8rem;
  width: 10.1176rem;
  height: 3.1111rem;
  background-color: black;
}
.styles_addClientButton__21bMB:hover {
  background-color: rgba(0, 0, 0, 0.678);
}
.styles_addClientButton__21bMB > span {
  color: white;
}

/*
 * @author Oleg Khalidov <brooth@gmail.com>.
 * -----------------------------------------------
 * Freelance software development:
 * Upwork: https://www.upwork.com/freelancers/~01d93e90d5b37c48d2
 */
.styles_searchBar__2EYlO {
  flex: 1 1;
  width: auto;
  height: 3.1111rem;
  display: flex;
}
.styles_control__3aOY7 {
  margin-left: -1.3rem;
  padding: 0px 0px 0px 2.9rem;
  width: 18rem;
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  overflow: hidden;
  background-color: white;
  border: 1px solid #d2d2d2;
  border-radius: 99rem;
  white-space: nowrap;
  text-align: left;
  font-family: Circular Std Book;
  font-style: normal;
  font-weight: normal;
  font-size: 1rem;
  color: rgba(32, 32, 32, 1);
  transition: width 0.3s ease-in-out;
}
.styles_control__3aOY7:not(:-ms-input-placeholder) {
  width: 100%;
  -ms-transition: width 0.32s ease-in-out;
  transition: width 0.32s ease-in-out;
}
.styles_control__3aOY7:not(:placeholder-shown),
.styles_control__3aOY7:focus {
  width: 100%;
  transition: width 0.32s ease-in-out;
}
.styles_searchIcon__1TSCd {
  position: relative;
  top: 1rem;
  left: 1.1rem;
  width: 1.4rem;
}
.styles_closeIcon__3VpG0 {
  display: none;
  position: relative;
  top: 1rem;
  right: 2.1rem;
  width: 1.4rem;
  cursor: pointer;
}
.styles_control__3aOY7:not(:-ms-input-placeholder) ~ .styles_closeIcon__3VpG0 {
  display: inline;
}
.styles_control__3aOY7:not(:placeholder-shown) ~ .styles_closeIcon__3VpG0,
.styles_control__3aOY7:focus ~ .styles_closeIcon__3VpG0 {
  display: inline;
}

.styles_searchButton__APsI3 {
  margin-left: 0.6rem;
  height: 100%;
  width: 8.0588rem;
  display: flex;
  justify-content: center;
  align-items: center;
  background: linear-gradient(302.39deg, #084edd -18.61%, #489ff9 113.13%);
  border: 0px;
  border-radius: 1.7778rem;
  overflow: hidden;
}
.styles_searchButton__APsI3:hover {
  opacity: 0.9;
}
.styles_searchButton__APsI3:active {
  opacity: 1;
}
.styles_searchButton__APsI3 > span {
  letter-spacing: 0px;
  font-family: Circular Std;
  font-style: normal;
  font-weight: normal;
  font-size: 1rem;
  color: rgba(255, 255, 255, 1);
  text-transform: uppercase;
}

/*
 * @author Oleg Khalidov <brooth@gmail.com>.
 * -----------------------------------------------
 * Freelance software development:
 * Upwork: https://www.upwork.com/freelancers/~01d93e90d5b37c48d2
 */

.styles_datafilterBloc__77YtI {
  width: 39.6471rem;
  padding: 3.3rem 2.7rem 2.7rem 2.7rem;
}

.styles_datafilterBloc__77YtI .styles_header__2pV1h {
  width: 100%;
  height: 1.8824rem;
  display: inline-flex;
  justify-content: space-between;
  align-items: center;
}

.styles_datafilterBloc__77YtI .styles_header__2pV1h > span {
  font-family: Circular Std;
  font-style: normal;
  font-weight: normal;
  font-size: 1.8824rem;
  color: rgba(32, 32, 32, 1);
}

.styles_form__2Usar {
  padding-top: 1.7647rem;
}

.styles_form__2Usar > form > * {
  margin-top: 1.5rem;
}

.styles_form__2Usar .styles_row__1OM85 {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.styles_info__3AqLU {
  width: 100%;
  height: 3.5294rem;
  display: inline-flex;
  align-items: center;
  background-color: #f6f6f6;
  border-radius: 12px;
  border: 1px solid #e4e4e4;
  padding-left: 1.1rem;
  padding-right: 1.1rem;
}
.styles_info__3AqLU > span {
  padding-left: 0.8rem;
  font-family: Circular Std Book;
  font-style: normal;
  font-weight: normal;
  font-size: 0.8824rem;
  color: rgba(72, 72, 72, 1);
}

.styles_blackTagsInput__BFsNL
  > div[class*="react-tagsinput"]
  span[class="react-tagsinput-tag"] {
  background-color: rgb(32, 32, 32, 1);
  border: 1px solid rgb(32, 32, 32, 1);
  }

.styles_form__2Usar > form > .styles_saveButton__1rLg3 {
  margin-top: 3rem;
  height: 3.5rem;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: linear-gradient(302.39deg, #084edd -18.61%, #489ff9 113.13%);
  border: 0px;
  border-radius: 1.7778rem;
  overflow: hidden;
}
.styles_saveButton__1rLg3 > span {
  color: white;
}
/*
 * @author Oleg Khalidov <brooth@gmail.com>.
 * -----------------------------------------------
 * Freelance software development:
 * Upwork: https://www.upwork.com/freelancers/~01d93e90d5b37c48d2
 */

.styles_list__2ASKG {
  width: 100%;
  margin-top: 1.9rem;
}

.styles_list__2ASKG>.styles_row__3nU-t>div {
  margin-bottom: 1.9rem;
}

.styles_title__MvqDH {
  font-size: 15px;
  text-align: left;
  font-family: Circular Std Bold;
  font-style: normal;
  font-weight: normal;
  color: rgba(32, 32, 32, 1);
}

.styles_subtitle__3QXLr {
  margin: 10px 0px 0px 23px;
  text-align: left;
  font-family: Circular Std Book;
  font-style: normal;
  font-weight: normal;
  font-size: 0.9rem;
  color: #7d7d7d;
  letter-spacing: 0.1px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.styles_addDataFilterButton__2cQ5f {
  margin-left: 1.8rem;
  width: 12rem;
  height: 42px;
  background-color: black;
}

.styles_addDataFilterButton__2cQ5f:hover {
  background-color: rgba(0, 0, 0, 0.678);
}

.styles_addDataFilterButton__2cQ5f>span {
  color: white;
}

.styles_dropzone__2we3f {
  padding: 8px 0px 0px 0px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.styles_dropzone__2we3f>p {
  margin-top: 18px;
  font-family: Circular Std;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  color: #9d9b9b;
}
/*
 * @author Oleg Khalidov <brooth@gmail.com>.
 * -----------------------------------------------
 * Freelance software development:
 * Upwork: https://www.upwork.com/freelancers/~01d93e90d5b37c48d2
 */

.styles_list__3F3-h {
  width: 100%;
  margin-top: 1rem;
  justify-content: center;
}

.styles_list__3F3-h > div[class*="row"] > div {
  margin: 0 1rem 1.9rem 1rem;
  padding: 1.2rem 1rem 1rem 1rem;
  width: 100%;
  height: 100%;
  min-height: 7rem;
  min-width: 20rem;
  border: 1px solid #d1d1d1;
  border-radius: 1.2941rem;
  display: flex;
  flex-direction: column;
}

.styles_list__3F3-h > div[class*="row"] > div[class*="active"] {
  cursor: pointer;
}

.styles_list__3F3-h > div[class*="row"] p[class*="title"] {
  text-align: left;
  font-family: Circular Std Bold;
  font-style: normal;
  font-weight: normal;
  font-size: 1.1rem;
  color: rgba(32, 32, 32, 1);
}

.styles_list__3F3-h > div[class*="row"] > div > svg {
  height: 88px;
}

.styles_list__3F3-h > div[class*="row"] .styles_error__3tphY {
  height: 88px;
}

.styles_list__3F3-h > div[class*="row"] .styles_error__3tphY > [class="title"] {
  padding-top: 30px;
  text-align: center;
  font-family: Circular Std Book;
  font-style: normal;
  font-weight: normal;
  font-size: 1.2rem;
  color: rgb(134, 36, 36);
}

.styles_list__3F3-h > div[class*="row"] .styles_error__3tphY > [class="message"] {
  padding-top: 15px;
  text-align: center;
  font-family: Circular Std;
  font-style: normal;
  font-weight: normal;
  font-size: 0.9rem;
  color: rgb(134, 36, 36);
}

.styles_list__3F3-h > div[class*="row"] .styles_content__GE9_j {
  margin-top: 6px;
  height: 80px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.styles_list__3F3-h > div[class*="row"] .styles_content__GE9_j > [class="rating"] {
  display: flex;
  flex-direction: row;
  align-items: center;
  text-align: left;
  font-family: Circular Std Bold;
  font-style: normal;
  font-weight: normal;
  font-size: 1.1rem;
  color: rgba(32, 32, 32, 1);
}

.styles_list__3F3-h > div[class*="row"] .styles_content__GE9_j > [class="rating"] > span {
  padding-left: 5px;
  font-weight: normal;
  font-size: 14px;
}

.styles_list__3F3-h > div[class*="row"] .styles_content__GE9_j > [class="rating"] > span[class~="silent"] {
  color: rgb(143, 143, 143);
  font-family: Circular Std;
}

.styles_list__3F3-h > div[class*="row"] .styles_content__GE9_j > [class*="danger"] {
  background-color: #fb3d42;
}

/*
 * @author Oleg Khalidov <brooth@gmail.com>.
 * -----------------------------------------------
 * Freelance software development:
 * Upwork: https://www.upwork.com/freelancers/~01d93e90d5b37c48d2
 */

table {
  width: 100%;
  margin-top: 1.9rem;
  border-collapse: separate;
  text-align: center;
}

table > thead {
  background-color: #f9fcfd;
}

table tr > * {
  vertical-align: middle;
}

table > tbody > tr > td:first-child {
  text-align: left;
  padding-left: 12px;
  padding-right: 12px;
  white-space: nowrap;
}
table > thead > tr > th {
  padding: 7px 7px;
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 26px;
  letter-spacing: 0.04em;
  color: #777777;
  border-top: 1px solid #e5e5e5;
  border-bottom: 1px solid #e5e5e5;
  border-right: 1px solid #e5e5e5;
}

table > thead > tr:nth-child(2) > th {
  border-top: 0px;
}

table > thead > tr:first-child > th:first-child {
  border-left: 1px solid #e5e5e5;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
}

table > thead > tr:first-child > th:last-child {
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
}

table > tbody > tr[class="spacer"] {
  height: 15px;
}

table > tbody > tr > td {
  padding: 14px 2px;
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 26px;
  letter-spacing: 0.04em;
  color: #333333;
  border-left: 1px solid #e5e5e5;
  border-top: 1px solid #e5e5e5;
  border-bottom: 1px solid #e5e5e5;
}

table > tbody > tr > td:first-child {
  border-left: 1px solid #e5e5e5;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
}

table > tbody > tr > td:last-child {
  border-right: 1px solid #e5e5e5;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
}

table > tbody > tr > td:nth-child(3) {
  font-weight: bold;
}

table > tbody > tr > td:nth-child(9),
table > tbody > tr > td:nth-child(10),
table > tbody > tr > td:nth-child(11) {
  background-color: #ffefe7;
}

table > tbody > tr > td > p[class="locationAddress"] {
  color: #777777;
  font-weight: normal;
  font-size: 12px;
}

.styles_loadMoreButton__3CsGe {
  margin-top: 20px;
  margin-bottom: 50px;
  padding: 5px 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: linear-gradient(302.39deg, #084edd -18.61%, #489ff9 113.13%);
  border: 0px;
  border-radius: 1.7778rem;
  overflow: hidden;
}
.styles_loadMoreButton__3CsGe:hover {
  opacity: 0.9;
}
.styles_loadMoreButton__3CsGe:active {
  opacity: 1;
}
.styles_loadMoreButton__3CsGe > span {
  letter-spacing: 0px;
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 28px;
  letter-spacing: 0.04em;
  color: rgba(255, 255, 255, 1);
  text-transform: uppercase;
}

.styles_statusIcon__3b_3n {
  margin-left: 4px;
  margin-bottom: 2px;
  color: #c73d3d;
}

.styles_bulkCampaignBloc__1xw5W {
  width: 39.6471rem;
  padding: 3.3rem 2.7rem 2.7rem 2.7rem;
}

.styles_bulkCampaignBloc__1xw5W > div[class="header"] {
  width: 100%;
  height: 1.8824rem;
  display: inline-flex;
  justify-content: space-between;
  align-items: center;
}

.styles_bulkCampaignBloc__1xw5W > div[class="header"] > span {
  font-family: Circular Std;
  font-style: normal;
  font-weight: normal;
  font-size: 1.8824rem;
  color: rgba(32, 32, 32, 1);
}
.styles_bulkCampaignBloc__1xw5W > div[class="subheader"] {
  margin-top: 10px;
  align-self: flex-start;
  font-family: Circular Std;
  font-style: normal;
  font-weight: normal;
  font-size: 17px;
  color: rgb(128, 127, 127);
}
.styles_bulkCampaignBloc__1xw5W > div[class="body"] {
  margin: 40px -10px 10px -10px;
  width: 100%;
  height: 40vh;
  padding: 0px;
  overflow: scroll;
  background-color: #f6f6f6;
  border: 0px;
  border-radius: 0.6667rem;
  white-space: nowrap;
  text-align: left;
  font-family: Circular Std;
  font-style: normal;
  font-weight: normal;
  font-size: 1rem;
  color: rgba(32, 32, 32, 1);
}
/*
 * @author Oleg Khalidov <brooth@gmail.com>.
 * -----------------------------------------------
 * Freelance software development:
 * Upwork: https://www.upwork.com/freelancers/~01d93e90d5b37c48d2
 */

.styles_locationBloc__3oCa7 {
  width: 39.6471rem;
  padding: 3.3rem 2.7rem 2.7rem 2.7rem;
}

.styles_locationBloc__3oCa7 .styles_header__ZV3Nk {
  width: 100%;
  height: 1.8824rem;
  display: inline-flex;
  justify-content: space-between;
  align-items: center;
}

.styles_locationBloc__3oCa7 .styles_header__ZV3Nk>span {
  font-family: Circular Std;
  font-style: normal;
  font-weight: normal;
  font-size: 1.8824rem;
  color: rgba(32, 32, 32, 1);
}

.styles_form__1ctS2 {
  padding-top: 1.7647rem;
}

.styles_form__1ctS2>form>* {
  margin-top: 1.5rem;
}

.styles_form__1ctS2>form [class*="rbt"] {
  border: none;
}

.styles_form__1ctS2 .styles_fieldWithRightIcon__GRqbe>input {
  padding-right: 3.2rem;
}

.styles_form__1ctS2 .styles_resetLinkIcon__27SFB {
  position: absolute;
  right: 3.8rem;
  margin-top: -2.1rem;
  color: #084edd;
}

.styles_form__1ctS2 .styles_resetLinkIcon__27SFB[data-loading=true] {
  -webkit-animation: styles_spin__1XsKN 1s linear infinite;
  animation: styles_spin__1XsKN 1s linear infinite;
}

@-webkit-keyframes styles_spin__1XsKN {
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes styles_spin__1XsKN {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.styles_form__1ctS2 .styles_copyReviewLinkIcon__1izSh {
  position: absolute;
  right: 3.8rem;
  margin-top: -2.36rem;
}

.styles_form__1ctS2>form>.styles_taglist__1siWu>div[class*="react-tagsinput"] {
  margin-top: 0.6rem;
  padding: 0.7rem 1rem 0.4rem 1rem;
  width: 100%;
  background-color: #f6f6f6;
  border: 0px;
  border-radius: 0.6667rem;
}

.styles_form__1ctS2>form>.styles_taglist__1siWu>div[class*="react-tagsinput"] input[class="react-tagsinput-input"] {
  width: 110px;
}

.styles_form__1ctS2>form>.styles_taglist__1siWu>div[class*="react-tagsinput"] span[class="react-tagsinput-tag"] {
  white-space: nowrap;
  text-align: left;
  font-family: Circular Std;
  font-style: normal;
  font-weight: normal;
  font-size: 1rem;
  color: white;
  background-color: rgb(53, 100, 187);
  border: 1px solid rgb(30, 88, 128);
  border-radius: 5px;
}

.styles_form__1ctS2>form>.styles_taglist__1siWu>div[class*="react-tagsinput"] a[class="react-tagsinput-remove"] {
  color: white;
}

.styles_form__1ctS2>form>.styles_taglist__1siWu>span[class="label"] {
  opacity: 0.7;
  overflow: visible;
  white-space: nowrap;
  text-align: left;
  font-family: Circular Std Book;
  font-weight: normal;
  font-size: 0.8889rem;
  color: rgba(32, 32, 32, 1);
}

.styles_form__1ctS2>form div[class="checkboxes"] {
  padding-top: 5px;
  display: flex;
  justify-content: flex-end;
  position: absolute;
  right: 42px;
}

.styles_form__1ctS2>form div[class="checkboxes"]>label {
  padding-left: 5px;
  font-family: Circular Std Book;
  font-weight: normal;
  font-size: 0.8889rem;
  color: #666;
}

.styles_form__1ctS2>form div[class="checkboxes"]>label>input {
  margin: 5px;
}


.styles_form__1ctS2>form>.styles_taglist__1siWu[id="blacklist"]>div[class*="react-tagsinput"] input[class="react-tagsinput-input"] {
  width: 165px;
}

.styles_form__1ctS2>form>.styles_taglist__1siWu[id="blacklist"]>div[class*="react-tagsinput"] span[class="react-tagsinput-tag"] {
  background-color: rgb(43, 41, 41);
  border: 1px solid black;
}

.styles_form__1ctS2>form>.styles_gConnectPanel__nDI4a {
  margin-top: 2.5rem;
  border: 1px solid #d7d7d7;
  border-radius: 0.7059rem;
  padding: 0.8824rem 1.1rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.styles_gConnectPanel__nDI4a>.styles_gLogoBorder__FEWQm {
  margin-right: 15px;
  background-color: #e8e8e8;
  border-radius: 999px;
  padding: 7px 7px 5px 8px;
}

.styles_gConnectPanel__nDI4a>.styles_gLogoBorder__FEWQm>img {
  width: 18px;
  height: 18px;
}

.styles_gConnectPanel__nDI4a [class*="error"] {
  line-height: 2rem;
}

.styles_gConnectPanel__nDI4a h5 {
  font-family: Circular Std Medium;
  font-size: 1.0588rem;
  color: rgba(32, 32, 32, 1);
}

.styles_gConnectPanel__nDI4a h6 {
  font-family: Circular Std Book;
  font-size: 0.8824rem;
  color: rgba(32, 32, 32, 1);
  padding-top: 0.5882rem;
}

.styles_form__1ctS2>form .styles_gConnectButton__2sW0h {
  margin-left: 15px;
  padding: 14px 20px;
  background: linear-gradient(302.39deg, #084edd -18.61%, #489ff9 113.13%);
}

.styles_form__1ctS2>form .styles_gDisconnectButton__13jFT {
  margin-left: 15px;
  padding: 14px 20px;
  background: #e1e9fb;
}

.styles_form__1ctS2>form>.styles_saveButton__3U_GR {
  margin-top: 28px;
  height: 45px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: linear-gradient(302.39deg, #084edd -18.61%, #489ff9 113.13%);
  border: 0px;
  border-radius: 1.7778rem;
  overflow: hidden;
}

.styles_gConnectButton__2sW0h>span,
.styles_saveButton__3U_GR>span {
  color: white;
}

.styles_form__1ctS2>form .styles_gDisconnectButton__13jFT>span {
  color: #084edd;
}

.styles_imagePicker___r3Jd {
  margin-top: 40px;
  margin-bottom: -50px;
  width: 100%;
  height: 150px;
  /* background-color: khaki; */
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px dashed #ddd;
  border-radius: 10px;
}

.styles_imagePicker___r3Jd * {
  max-height: 150px;
  position: absolute;
}

.styles_imagePicker___r3Jd img {
  z-index: 1;
}

.styles_imagePicker___r3Jd input {
  z-index: 100;
  width: 100%;
  height: 100%;
  opacity: 0;
  cursor: pointer;
}

.styles_imagePicker___r3Jd input::file-selector-button {
  display: none;
}

.styles_imagePicker___r3Jd>div {
  width: 120px;
  height: 120px;
  color: #888;
  display: flex;
  justify-content: center;
  align-items: center;
}

.styles_locationPicker__IyjUg {
  margin-top: 50px;
  max-height: 80vh;
  width: 100%;
  height: 34rem;
  border: 1px solid #eee;
  border-radius: 10px;
  overflow-x: scroll;
}

.styles_locationPickerItem__1L-lr {
  width: 34.3rem;
  padding: 15px 20px;
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
  border-radius: 10px;
}

.styles_locationPickerItem__1L-lr:hover {
  background: #489ff955;
}

.styles_locationPickerItem__1L-lr svg {
  margin-right: 15px;
}

.styles_locationPickerItem__1L-lr>[class*="content"] {
  display: flex;
  flex-direction: column;
}

.styles_locationPickerItem__1L-lr>[class*="content"]>h5 {
  padding-bottom: 6px;
  font-family: Circular Std Medium;
  font-size: 1rem;
  color: rgba(32, 32, 32, 1);
}

.styles_locationPickerItem__1L-lr>[class*="content"]>p {
  font-family: Circular Std Medium;
  font-size: 0.9rem;
  color: rgb(80, 80, 80);
}

@-webkit-keyframes styles_waves__3bGSC {
  0% {
    background-position: 0vw 0;
  }

  100% {
    background-position: 1000000vw 0;
  }
}

@keyframes styles_waves__3bGSC {
  0% {
    background-position: 0vw 0;
  }

  100% {
    background-position: 1000000vw 0;
  }
}
/*
 * @author Oleg Khalidov <brooth@gmail.com>.
 * -----------------------------------------------
 * Freelance software development:
 * Upwork: https://www.upwork.com/freelancers/~01d93e90d5b37c48d2
 */

.styles_list__VV8_t {
  width: 100%;
  margin-top: 1.9rem;
}

.styles_list__VV8_t > .styles_row__2zNr- > div {
  margin-bottom: 1.9rem;
}

.styles_addLocationButton__1OXB3 {
  margin-left: 1.8rem;
  width: 11.5882rem;
  height: 42px;
  background-color: #00c455;
}
.styles_addLocationButton__1OXB3:hover {
  background-color: #00c455cb;
}
.styles_addLocationButton__1OXB3:active {
  background-color: #00c455;
}
.styles_addLocationButton__1OXB3 > span {
  color: white;
}

/*
 * @author Oleg Khalidov <brooth@gmail.com>.
 * -----------------------------------------------
 * Freelance software development:
 * Upwork: https://www.upwork.com/freelancers/~01d93e90d5b37c48d2
 */

.styles_container__2qBhn {
  margin-top: 1rem;
  width: 100%;
}

.styles_container__2qBhn>div[class~="header"] {
  width: 100%;
  display: flex;
  flex-direction: row;
}

.styles_container__2qBhn>div[class~="header"]>div:first-child {
  flex: 1 1;
}

.styles_dateTimePicker__2taJw {
  width: 150px;
  margin-left: 10px;
  position: relative;
}
.styles_dateTimePicker__2taJw input {
  border: 1px solid #d1d1d1;
  border-radius: 0.6667rem;
}
.styles_dateTimePicker__2taJw div[class~="resetButton"] {
  left: 123px;
  bottom: 16px;
  right: unset;
  color: #cccccc;
}

.styles_list__1NRjr {
  width: 100%;
  margin-top: 3rem;
  justify-content: center;
}

.styles_list__1NRjr>div[class*="row"]>div {
  margin: 0 1rem 1.9rem 1rem;
  padding: 1.2rem 1rem 1rem 1rem;
  width: 100%;
  height: 100%;
  min-height: 7rem;
  min-width: 20rem;
  border: 1px solid #d1d1d1;
  border-radius: 1.2941rem;
  display: flex;
  flex-direction: column;
}

.styles_list__1NRjr>div[class*="row"]>div[class*="active"] {
  cursor: pointer;
}

.styles_list__1NRjr>div[class*="row"] p[class*="title"] {
  text-align: right;
  font-family: Circular Std Bold;
  font-style: normal;
  font-weight: normal;
  font-size: 1.1rem;
  color: rgba(32, 32, 32, 1);
}

.styles_list__1NRjr>div[class*="row"]>div>svg {
  height: 88px;
}

.styles_list__1NRjr>div[class*="row"] .styles_error__2ICO9 {
  height: 88px;
}

.styles_list__1NRjr>div[class*="row"] .styles_error__2ICO9>[class="title"] {
  padding-top: 30px;
  text-align: center;
  font-family: Circular Std Book;
  font-style: normal;
  font-weight: normal;
  font-size: 1.2rem;
  color: rgb(134, 36, 36);
}

.styles_list__1NRjr>div[class*="row"] .styles_error__2ICO9>[class="message"] {
  padding-top: 15px;
  text-align: center;
  font-family: Circular Std;
  font-style: normal;
  font-weight: normal;
  font-size: 0.9rem;
  color: rgb(134, 36, 36);
}

.styles_list__1NRjr>div[class*="row"] .styles_content__b9w-J {
  height: 88px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.styles_list__1NRjr>div[class*="row"] .styles_content__b9w-J>[class="averageRating"] {
  height: 30px;
  display: flex;
  flex-direction: row;
  align-items: center;
  text-align: left;
  font-family: Circular Std Bold;
  font-style: normal;
  font-weight: normal;
  font-size: 1.1rem;
  color: rgba(32, 32, 32, 1);
}

.styles_list__1NRjr>div[class*="row"] .styles_content__b9w-J>[class="averageRating"]>span {
  padding-right: 5px;
}

.styles_list__1NRjr>div[class*="row"] .styles_content__b9w-J>[class="totalReviews"] {
  padding-top: 3px;
  text-align: center;
  font-family: Circular Std;
  font-style: normal;
  font-weight: normal;
  font-size: 1.04rem;
  color: #707070;
}

.styles_list__1NRjr>div[class*="row"] .styles_content__b9w-J>[class*="unrepliedReviews"] {
  margin-top: 12px;
  text-align: center;
  font-family: Circular Std;
  font-style: normal;
  font-weight: normal;
  font-size: 1.04rem;
  color: white;
  background-color: #c2c2c2;
  border-radius: 9999px;
  padding: 6px 12px;
}

.styles_list__1NRjr>div[class*="row"] .styles_content__b9w-J>[class*="danger"] {
  background-color: #fb3d42;
}
/*
 * @author Oleg Khalidov <brooth@gmail.com>.
 * -----------------------------------------------
 * Freelance software development:
 * Upwork: https://www.upwork.com/freelancers/~01d93e90d5b37c48d2
 */

.styles_container__3CV4b {
  width: 100%;
  max-width: 1000px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.styles_container__3CV4b > p {
  margin-left: 20px;
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 22px;
  letter-spacing: 0.04em;
  color: #5e5f60;
  align-self: flex-start;
}
.styles_container__3CV4b > p > b {
  font-weight: bold;
}

.styles_list__1ROo1 {
  width: 100%;
  margin-top: 1.9rem;
  margin-left: 1.1rem;
  margin-right: 1.1rem;
}

.styles_list__1ROo1 > div {
  margin-top: 3rem;
  background: #ffffff;
  border: 1px solid #e7e7e7;
  box-sizing: border-box;
  border-radius: 20px;
  padding: 30px 30px 15px 30px;
}

.styles_list__1ROo1 .styles_header__2J2Mt {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;
}

.styles_list__1ROo1 .styles_header__2J2Mt h1 {
  font-family: Poppins;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 34px;
  color: #333333;
}

.styles_list__1ROo1 .styles_header__2J2Mt h3 {
  cursor: pointer;
  margin-top: 5px;
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 26px;
  letter-spacing: 0.04em;
  color: #585858;
  opacity: 0.7;
}
.styles_list__1ROo1 .styles_header__2J2Mt h3 > svg {
  margin-left: 9px;
  margin-bottom: 3px;
}

.styles_list__1ROo1 .styles_header__2J2Mt p {
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 24px;
  text-align: right;
  letter-spacing: 0.04em;
  color: #aaaaaa;
}

.styles_devider__2DE9k {
  margin-top: 15px;
  margin-bottom: 15px;
  width: 100%;
  height: 2px;
}

.styles_improvetags__NhQqw {
  margin-top: 40px;
  display: flex;
  flex-direction: row;
}
.styles_improvetags__NhQqw > h3 {
  padding-top: 3px;
  flex-shrink: 0;
  font-family: Poppins;
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 26px;
  letter-spacing: 0.04em;
  color: #585858;
}

.styles_improvetags__NhQqw > ul {
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
}
.styles_improvetags__NhQqw > ul > li {
  margin-left: 10px;
  margin-bottom: 10px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 4px 16px;
  background: rgba(0, 111, 255, 0.1);
  border-radius: 4px;
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 24px;
  letter-spacing: 0.04em;
  color: #006fff;
}

.styles_comment__G36Gf {
  margin-top: 20px;
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 31px;
  letter-spacing: 0.04em;
  color: #6d6d6d;
}

.styles_delete__2oTkP {
  position: relative;
  left: calc(100% - 22px);
  bottom: -16px;
  width: 55px;
  height: 55px;
  cursor: pointer;
}

/*
 * @author Oleg Khalidov <brooth@gmail.com>.
 * -----------------------------------------------
 * Freelance software development:
 * Upwork: https://www.upwork.com/freelancers/~01d93e90d5b37c48d2
 */

.styles_container__2TThU {
  width: 100%;
  margin-top: 2.9rem;
  margin-left: 0.1rem;
  margin-right: 1.1rem;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.styles_locationsDropdown__3SR-V > div:first-child > span:first-child {
  font-family: Poppins;
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 26px;
  display: flex;
  align-items: flex-end;
  letter-spacing: 0.04em;
  color: #202020;
}

.styles_showRepliedDropdown__Ca6uf {
  margin-left: 20px;
}

.styles_selectedStars__3H2PM {
  width: auto;
  min-width: 300px;
  margin-left: 20px;
}

.styles_selectedStars__3H2PM > div[class*='-container'] > nowrap > div[class*='-control'] > svg {
  margin-right: -7px;
}
.styles_selectedStars__3H2PM > div[class*='-container'] > nowrap > div[class*='-control'] div[class*='-multiValue'] {
  border-radius: 10px;
  font-family: Poppins;
  font-style: normal;
  font-size: 12px;
}
.styles_selectedStars__3H2PM > div[class*='-container'] > nowrap > div[class*='-control'] div[class*='-multiValue'] > div:nth-child(2) {
  border-radius: 10px;
  background-color: transparent;
}
/*
 * @author Oleg Khalidov <brooth@gmail.com>.
 * -----------------------------------------------
 * Freelance software development:
 * Upwork: https://www.upwork.com/freelancers/~01d93e90d5b37c48d2
 */

.styles_list__2d6LB {
  width: 100%;
  margin-top: 1.9rem;
  margin-left: 1.1rem;
  margin-right: 1.1rem;
}

.styles_list__2d6LB>.styles_row__3sCVW>div {
  padding: 2rem 1.6rem 1.6rem 1.6rem;
  width: 100%;
  height: 100%;
  min-height: 11.1rem;
  border: 1px solid #d1d1d1;
  border-radius: 1.2941rem;
  display: flex;
  flex-direction: column;
  margin-bottom: 1.9rem;
}

.styles_list__2d6LB>.styles_row__3sCVW>div {
  padding: 40px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.styles_list__2d6LB>.styles_row__3sCVW>div>div:first-child {
  display: flex;
  flex-direction: row;
  flex: 3 1;
  border-right: 1px solid #f4f5f8;
  margin-right: 40px;
}

.styles_list__2d6LB>.styles_row__3sCVW>div>div:first-child>img {
  width: 50px;
  height: 50px;
  border-radius: 50%;
}

.styles_list__2d6LB>.styles_row__3sCVW>div>div:first-child>p {
  padding: 12px 17px 0px 15px;
  font-family: Poppins;
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 26px;
  color: #202020;
}

.styles_list__2d6LB>.styles_row__3sCVW>div>div:last-child {
  display: flex;
  flex-direction: column;
  flex: 10 1;
}

.styles_list__2d6LB>.styles_row__3sCVW>div>div:last-child>p:first-child {
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 26px;
  letter-spacing: 0.04em;
  color: #777777;
  margin-bottom: 20px;
}

.styles_list__2d6LB>.styles_row__3sCVW>div>div:last-child>p:first-child>b {
  font-weight: 600;
  color: #202020;
}

.styles_list__2d6LB>.styles_row__3sCVW>div>div:last-child>p:first-child>span[class*="alert"] {
  padding: 4px 7px;
  margin-left: 20px;
  background: rgba(234, 67, 53, 0.1);
  border: 1px solid #ea4335;
  box-sizing: border-box;
  border-radius: 12px;
  font-weight: 500;
  font-size: 13px;
  line-height: 24px;
  color: #ea4335;
}

.styles_list__2d6LB>.styles_row__3sCVW>div>div:last-child>div[class="stars"]>svg {
  margin-right: 4px;
  margin-bottom: 6px;
}

.styles_list__2d6LB>.styles_row__3sCVW>div>div:last-child>div[class="stars"]>span {
  margin-left: 8px;
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 24px;
  letter-spacing: 0.04em;
  color: #a9aaaf;
}

.styles_list__2d6LB>.styles_row__3sCVW>div>div:last-child>.styles_comment__1fHWu {
  margin-top: 10px;
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 28px;
  letter-spacing: 0.04em;
  color: #777777;
}

.styles_list__2d6LB>.styles_row__3sCVW>div>div:last-child>.styles_comment__1fHWu>span {
  margin-left: 6px;
  font-family: Poppins;
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 28px;
  letter-spacing: 0.04em;
  color: #1c9afb;
  cursor: pointer;
}

.styles_loadMoreButton__14Oj8 {
  margin-top: 20px;
  margin-bottom: 50px;
  padding: 5px 70px;
  width: 8.0588rem;
  display: flex;
  justify-content: center;
  align-items: center;
  background: linear-gradient(302.39deg, #084edd -18.61%, #489ff9 113.13%);
  border: 0px;
  border-radius: 1.7778rem;
  overflow: hidden;
}

.styles_loadMoreButton__14Oj8:hover {
  opacity: 0.9;
}

.styles_loadMoreButton__14Oj8:active {
  opacity: 1;
}

.styles_loadMoreButton__14Oj8>span {
  letter-spacing: 0px;
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 28px;
  letter-spacing: 0.04em;
  color: rgba(255, 255, 255, 1);
  text-transform: uppercase;
}

.styles_replyPanel__2ksWp {
  border-top: 1px solid #d1d1d1;
  margin-top: 20px;
  position: relative;
  display: flex;
  flex-direction: column;
}

.styles_replyPanel__2ksWp>.styles_reviewPanelWrapper__iEue3 {
  margin-top: 32px;
  margin-bottom: 19px;
  display: flex;
  align-items: center;
}

.styles_replyPanel__2ksWp>.styles_reviewPanelWrapper__iEue3>span {
  margin-left: 10px;
  font-family: Poppins;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 26px;
  color: #202020;
  flex-grow: 1;
}

.styles_replyPanel__2ksWp>p {
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 28px;
  letter-spacing: 0.04em;
  color: #777777;
}

.styles_replyPanel__2ksWp .styles_closeIcon__1rZZt {
  cursor: pointer;
  align-self: flex-start;
  margin-top: 10px;
}

.styles_replyPanel__2ksWp textarea {
  height: 80px;
  width: 100%;
  border: none;
  border-bottom: 1px solid #d1d1d1;
  font-family: 'Circular Std';
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 0.04em;
  color: #333;
}

.styles_replyPanel__2ksWp textarea::-webkit-input-placeholder {
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 20px;
  color: #a9aaaf;
}

.styles_replyPanel__2ksWp textarea:-ms-input-placeholder {
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 20px;
  color: #a9aaaf;
}

.styles_replyPanel__2ksWp textarea::placeholder {
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 20px;
  color: #a9aaaf;
}

.styles_replyButtons__2XEZI {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  align-items: center;
}

.styles_bulkReplyButton__scdoP,
.styles_submitReplyButton__yFqGe,
.styles_replyButton__2UYRm {
  margin-top: 20px;
  margin-bottom: -10px;
  padding: 11px 20px;
  width: -webkit-min-content;
  width: min-content;
  display: flex;
  justify-content: center;
  align-items: center;
  background: linear-gradient(302.39deg, #1293f7 -18.61%, #49b0ff 113.13%);
  border: 0px;
  border-radius: 1.7778rem;
  overflow: hidden;
}

.styles_bulkReplyButton__scdoP {
  background: linear-gradient(302.39deg, #b832a2 -18.61%, #49b0ff 100%);
}

.styles_suggestReplyButton__jjQHO {
  margin-top: 12px;
  margin-bottom: -10px;
  padding: 8px;
  width: -webkit-min-content;
  width: min-content;
  display: flex;
  justify-content: center;
  align-items: center;
  background: linear-gradient(302.39deg, #b832a2 -18.61%, #49b0ff 100%);
  /* background: linear-gradient(302.39deg, #1293f7 -18.61%, #49b0ff 113.13%); */
  border: 0px;
  border-radius: 1.7778rem;
  overflow: hidden;
  align-self: flex-end;
}

.styles_bulkReplyButton__scdoP:hover,
.styles_submitReplyButton__yFqGe:hover,
.styles_replyButton__2UYRm:hover {
  opacity: 0.9;
}

.styles_bulkReplyButton__scdoP:active,
.styles_submitReplyButton__yFqGe:active,
.styles_replyButton__2UYRm:active {
  opacity: 1;
}

.styles_bulkReplyButton__scdoP>span,
.styles_submitReplyButton__yFqGe>span,
.styles_replyButton__2UYRm>span {
  letter-spacing: 0px;
  font-family: Circular Std;
  font-style: normal;
  font-weight: normal;
  font-size: 1rem;
  color: rgba(255, 255, 255, 1);
}

.styles_replyButton__2UYRm>span {
  margin-left: 10px;
}

.styles_submitReplyButton__yFqGe {
  margin-top: 13px;
  margin-right: -3px;
  padding: 8px 10px;
}

.styles_replyPanel__2ksWp .styles_actions__2C66x {
  flex-grow: 1;
  display: flex;
  justify-content: flex-end;
  margin-bottom: 7px;
}

.styles_replyPanel__2ksWp .styles_actions__2C66x span {
  padding-left: 7px;
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  letter-spacing: 0.04em;
  height: 13px;
  cursor: pointer;
}

.styles_replyPanel__2ksWp .styles_actions__2C66x>span:first-child {
  padding-right: 7px;
  color: #a9aaaf;
}

.styles_replyPanel__2ksWp .styles_actions__2C66x>span:last-child {
  border-left: 1px solid #A9AAAF;
  color: #ea4335;
}

.styles_sentimentIcon__2vrkK {
  width: 32px;
  height: 32px;
  align-self: flex-end;
  margin-bottom: -10px;
}

.styles_suggestionBox__2YWRv {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  align-self: center;
  background: rgba(28, 154, 251, 0.1);
  border: 1px solid #1C9AFB;
  box-sizing: border-box;
  border-radius: 20px;
  padding: 20px;
}

.styles_suggestionBox__2YWRv>div[class='header'] {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.styles_suggestionBox__2YWRv>div[class='header']>img {
  width: 34px;
  height: 34px;
  /* background: linear-gradient(271.64deg, #084EDD -21.95%, #6CD0FF 112.75%); */
  background: linear-gradient(302.39deg, #b832a2 -18.61%, #49b0ff 100%);
  border-radius: 99px;
  padding: 5px;
  margin-right: 10px;
}

.styles_suggestionBox__2YWRv>div[class='header']>span {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: #1C9AFB;
}

.styles_suggestionBox__2YWRv>div[class='header']>span>b {
  font-weight: bold;
}

.styles_suggestionBox__2YWRv>p {
  margin: 6px 10px 20px 46px;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 24px;
  color: #777777;
}


.styles_suggestionBox__2YWRv>div[class='footer'] {
  margin-left: 33px;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}

.styles_suggestionBox__2YWRv>div[class='footer']>div {
  margin-left: 10px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 8px 25px;
  height: 38px;
  background: transparent;
  border-radius: 100px;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
  letter-spacing: 0.04em;
  color: #777777;
  border: 1px solid #777777;
}

.styles_suggestionBox__2YWRv>div[class='footer']>div:nth-child(2) {
  border: none;
  background: #1C9AFB;
  color: #FFFFFF;
}

.styles_suggestionBox__2YWRv>div[class='footer']>.styles_suggestRefreshButton__3t_xT {
  margin-top: 1px;
  padding: 5px;
  height: 36px;
  width: 36px;
  align-items: center;
}

/*
 * @author Oleg Khalidov <brooth@gmail.com>.
 * -----------------------------------------------
 * Freelance software development:
 * Upwork: https://www.upwork.com/freelancers/~01d93e90d5b37c48d2
 */

@media (min-width: 1200px) {
  .styles_container__t1fq9 {
    position: relative !important;
    top: 0px !important;
    left: 0px !important;
    border-radius: 0px !important;
    background-color: #f4f5f8 !important;
    box-shadow: none !important;
  }
}

.styles_container__t1fq9 {
  position: absolute;
  top: 15px;
  left: 15px;
  z-index: 990;
  background-color: #fff;
  align-items: flex-start;
  border-radius: 10px;
  box-shadow: none;
  border: transparent 1px solid;
  padding: 5px;
}

.styles_container__t1fq9[data-expanded=true] {
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
  background-color: #f8f9fa;
  border: #f4f5f8 1px solid;
}

.styles_container__t1fq9 > div[class*='navbar-collapse'],
.styles_container__t1fq9 > div[class*='navbar-collapse'] > div {
  height: 100%;
  align-items: flex-start;
}

/* TOFIX: hide shadow when collapsed */
.styles_container__t1fq9:not(:has(button)) {
  margin: 100px !important;
  border-color: red !important;
}

.styles_container__t1fq9 > button {
  font-size: 16px;
}

.styles_item__E-f1C {
  margin: 6px 0px 6px 0px;
  width: 100%;
  min-width: 210px;
  height: 3.2941rem;
  background-color: transparent;
  border-radius: 9999px;
  display: flex;
  align-items: center;
  overflow: hidden;
  padding: 5px 20px;
  cursor: pointer;
  color: #5e5f60;
}

.styles_item__E-f1C > span {
  color: #5e5f60;
  font-family: "Circular Std", Helvetica;
  font-size: 1.1111rem;
  font-style: normal;
  font-weight: normal;
  margin-left: 0.8rem;
}

.styles_item__E-f1C[data-active="true"] {
  background-color: white;
}

.styles_item__E-f1C[data-active="true"] > span {
  color: #084edd;
}

/*
 * @author Oleg Khalidov <brooth@gmail.com>.
 * -----------------------------------------------
 * Freelance software development:
 * Upwork: https://www.upwork.com/freelancers/~01d93e90d5b37c48d2
 */

.styles_tabletTextsEditor__5t3nS {
  width: 39.6471rem;
  padding: 3.3rem 2.7rem 2.7rem 2.7rem;
}

.styles_tabletTextsEditor__5t3nS .styles_header__2jMW5 {
  width: 100%;
  height: 1.8824rem;
  display: inline-flex;
  justify-content: space-between;
  align-items: center;
}

.styles_tabletTextsEditor__5t3nS .styles_header__2jMW5 > span {
  font-family: Circular Std;
  font-style: normal;
  font-weight: normal;
  font-size: 1.8824rem;
  color: rgba(32, 32, 32, 1);
}

.styles_form__1K3qt {
  padding-top: 0rem;
}

.styles_form__1K3qt > form > * {
  margin-top: 1.5rem;
}

.styles_info__28ybd {
  width: 100%;
  height: 3.5294rem;
  display: inline-flex;
  align-items: center;
  background-color: #f6f6f6;
  border-radius: 12px;
  border: 1px solid #e4e4e4;
  padding-left: 1.1rem;
  padding-right: 1.1rem;
}
.styles_info__28ybd > span {
  padding-left: 0.8rem;
  font-family: Circular Std Book;
  font-style: normal;
  font-weight: normal;
  font-size: 0.8824rem;
  color: rgba(72, 72, 72, 1);
}

.styles_formPasswordField__7DZiY > input {
  padding-right: 3.2rem;
}

.styles_passwordHideIcon__32g2k {
  position: absolute;
  right: 4rem;
  margin-top: -2.36rem;
}

.styles_form__1K3qt > form > .styles_saveButton__19qan {
  margin-top: 3rem;
  height: 3.5rem;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: linear-gradient(302.39deg, #084edd -18.61%, #489ff9 113.13%);
  border: 0px;
  border-radius: 1.7778rem;
  overflow: hidden;
}
.styles_saveButton__19qan > span {
  color: white;
}
/*
 * @author Oleg Khalidov <brooth@gmail.com>.
 * -----------------------------------------------
 * Freelance software development:
 * Upwork: https://www.upwork.com/freelancers/~01d93e90d5b37c48d2
 */

.styles_topbarAction__M5iRk {
  height: 40px;
  width: 40px;
  padding: 0px;
  border: 1px solid #d2d2d2;
}
.styles_topbarAction__M5iRk > span {
  display: none;
}
.styles_logoutButton__36jps {
  height: 40px;
  border: 1px solid #d2d2d2;
}
.styles_logoutButton__36jps > span {
  margin-left: 1.1111rem;
}

/*
 * @author Oleg Khalidov <brooth@gmail.com>.
 * -----------------------------------------------
 * Freelance software development:
 * Upwork: https://www.upwork.com/freelancers/~01d93e90d5b37c48d2
 */

.styles_videoCampaignEditor__3hJkc {
  width: 40rem;
  padding: 3.3rem 2.7rem 2.7rem 2.7rem;
}

.styles_videoCampaignEditor__3hJkc .styles_header__SF1M1 {
  width: 100%;
  height: 1.8824rem;
  display: inline-flex;
  justify-content: space-between;
  align-items: center;
}

.styles_videoCampaignEditor__3hJkc .styles_header__SF1M1>span {
  font-family: Circular Std;
  font-style: normal;
  font-weight: normal;
  font-size: 1.8824rem;
  color: rgba(32, 32, 32, 1);
}

.styles_form__25emG {
  padding-top: 1.7647rem;
}

.styles_form__25emG>form>* {
  margin-top: 1.5rem;
}

.styles_form__25emG>form [class*="rbt"] {
  border: none;
}

.styles_form__25emG .styles_fieldWithRightIcon__2hC5K>input {
  padding-right: 3.2rem;
}

.styles_form__25emG>form>.styles_dateTimePicker__hfQE3 div[class='rdtPicker'] {
  bottom: 50px;
  left: 110px;
  box-shadow: 3px 1px 4px rgb(0 0 0 / 10%);
}

.styles_form__25emG>form>.styles_addQuestionButton__3141a {
  margin: 10px 0 0 auto;
  align-items: self-end;
  justify-content: flex-end;
  background-color: #dbdbff;
  color: #3636a5;
  width: -webkit-min-content;
  width: min-content;
  border-radius: 5px;
  padding: 7px 10px;
}

.styles_form__25emG>form>.styles_addQuestionButton__3141a>span {
  font-size: 13px !important;
}

.styles_form__25emG>form>.styles_saveButton__eQw_p {
  margin-top: 28px;
  height: 45px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: linear-gradient(302.39deg, #084edd -18.61%, #489ff9 113.13%);
  border: 0px;
  border-radius: 1.7778rem;
  overflow: hidden;
}

.styles_saveButton__eQw_p>span {
  color: white;
}
/*
 * @author Oleg Khalidov <brooth@gmail.com>.
 * -----------------------------------------------
 * Freelance software development:
 * Upwork: https://www.upwork.com/freelancers/~01d93e90d5b37c48d2
 */

.styles_list__IbUjL {
  width: 100%;
  margin-top: 1.9rem;
}

.styles_list__IbUjL>.styles_row__39ilY>div {
  margin-bottom: 14px;
}

.styles_list__IbUjL>.styles_row__39ilY>div>div {
  padding: 25px;
  min-height: 0px;
}

.styles_list__IbUjL>.styles_row__39ilY>div>div>div:first-child{
  padding-bottom: 16px;
}

.styles_list__IbUjL>.styles_row__39ilY>div>div>div>div {
  width: 100%;
}

.styles_list__IbUjL>.styles_row__39ilY>div>div>div>div>div {
  width: 100%;
}

.styles_list__IbUjL>.styles_row__39ilY>div>div>div>div>div>p[class="title"] {
  margin-bottom: 7px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  width: 100%;
}

.styles_list__IbUjL>.styles_row__39ilY>div>div>div>div>div>p[class="subtitle"] {
  margin-left: 2px;
  padding-top: 6px;
}

.styles_list__IbUjL .styles_icon__2VV79 {
  margin: 1px 10px 0 0;
  font-size: 18px;
  color: #656565;
}

.styles_addButton__xGzHq {
  margin-left: 1.8rem;
  height: 42px;
  background-color: #00c455;
}

.styles_addButton__xGzHq:hover {
  background-color: #00c455cb;
}

.styles_addButton__xGzHq:active {
  background-color: #00c455;
}

.styles_addButton__xGzHq>span {
  color: white;
}
/*
 * @author Oleg Khalidov <brooth@gmail.com>.
 * -----------------------------------------------
 * Freelance software development:
 * Upwork: https://www.upwork.com/freelancers/~01d93e90d5b37c48d2
 */

.styles_body__2WUDb {
  display: flex;
  flex-direction: row;
  width: 100%;
  grid-gap: 20px;
  gap: 20px;
}

.styles_leftPanel__1hWMA {
  flex: 1.5 1;
  display: flex;
  flex-direction: column;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  padding: 20px;
  background-color: #fafafa;
  border-radius: 3px;
}

.styles_leftPanel__1hWMA>.styles_searchInput__2y9BG {
  margin: 0 0 20px 0;
  width: 100%;
  border: 1px #ccc solid;
  border-radius: 5px;
  padding: 10px;
}

.styles_leftPanel__1hWMA>.styles_reply__1f8Fi {
  margin: 5px 0px;
  padding: 10px 20px;
  border-radius: 4px;
  width: 100%;
  background-color: #ECF2FD;
  color: #0B0A0A;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  grid-gap: 10px;
  gap: 10px;
}

.styles_leftPanel__1hWMA>.styles_reply__1f8Fi>input {
  margin: 0px 15px 0px 0;
}

.styles_leftPanel__1hWMA>.styles_reply__1f8Fi>div {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  grid-gap: 5px;
  gap: 5px;
}

.styles_leftPanel__1hWMA>.styles_reply__1f8Fi>div>span[class="title"] {
  font-size: 14px;
}

.styles_leftPanel__1hWMA>.styles_reply__1f8Fi>div>span[class="subtitle"] {
  font-size: 12px;
  color: #888;
}

.styles_leftPanel__1hWMA>.styles_reply__1f8Fi>span {
  font-size: 12px;
  color: #888;
}

.styles_leftPanel__1hWMA>.styles_seenReply__1ETDm {
  background-color: white;
  color: #474747;
}

.styles_leftPanel__1hWMA>.styles_selectedReply__6iLyQ {
  background-color: #3961F5;
  color: white;
}

.styles_leftPanel__1hWMA>.styles_selectedReply__6iLyQ>span,
.styles_leftPanel__1hWMA>.styles_selectedReply__6iLyQ>div>span[class="subtitle"] {
  color: #aaa;
}


.styles_rightPanel__1f3L0 {
  flex: 3 1;
  display: flex;
  flex-direction: column;
  padding: 20px;
  border-radius: 3px;
  background-color: transparent;
}

.styles_rightPanel__1f3L0[class~="selected"] {
  background-color: #f8f8f8;
}

.styles_rightPanel__1f3L0>h3 {
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 5px;
}

.styles_rightPanel__1f3L0>h4 {
  font-size: 14px;
  font-weight: normal;
  margin-bottom: 10px;
  color: #777;
}

.styles_rightPanel__1f3L0 .styles_question__2IU3v {
  margin-top: 30px;
}

.styles_rightPanel__1f3L0 .styles_question__2IU3v>h3 {
  font-size: 15px;
  font-weight: 500;
}

.styles_rightPanel__1f3L0 .styles_question__2IU3v>p {
  font-size: 13px;
  color: #777;
  margin-top: 10px;
}

.styles_rightPanel__1f3L0 .styles_question__2IU3v .styles_record__37wQ7 {
  margin-top: 10px;
  display: flex;
  flex-direction: row;
  border: 1px #eee solid;
  border-radius: 3px;
  align-items: center;
  padding: 10px 20px 10px 15px;
  grid-gap: 10px;
  gap: 10px;
  background-color: white;
}

.styles_rightPanel__1f3L0 .styles_question__2IU3v .styles_record__37wQ7 img {
  height: 80px;
  border-radius: 3px;
  border: 1px solid #eee;
  margin-right: auto;
}

.styles_rightPanel__1f3L0 .styles_question__2IU3v .styles_record__37wQ7 span {
  margin-right: auto;
}

.styles_rightPanel__1f3L0 .styles_question__2IU3v .styles_record__37wQ7 a {
  border-radius: 3px;
  font-size: 13px;
  padding: 5px 10px;
  text-decoration: none;
}

.styles_rightPanel__1f3L0 .styles_question__2IU3v .styles_record__37wQ7 a[class="preview"] {
  border: 1px #2A62FE solid;
  color: #2A62FE;
  background-color: white;
}

.styles_rightPanel__1f3L0 .styles_question__2IU3v .styles_record__37wQ7 a[class="preview"]>svg {
  margin-top: -2px;
  margin-left: 5px;
}

.styles_rightPanel__1f3L0 .styles_question__2IU3v .styles_record__37wQ7 a[class="download"] {
  border: 1px #2A62FE solid;
  background-color: #2A62FE;
  color: white;
}
/*
 * @author Oleg Khalidov <brooth@gmail.com>.
 * -----------------------------------------------
 * Freelance software development:
 * Upwork: https://www.upwork.com/freelancers/~01d93e90d5b37c48d2
 */

.styles_accountBloc__-c8Wi {
  width: 39.6471rem;
  padding: 3.3rem 2.7rem 2.7rem 2.7rem;
}

.styles_accountBloc__-c8Wi .styles_header__PrKZ- {
  width: 100%;
  height: 1.8824rem;
  display: inline-flex;
  justify-content: space-between;
  align-items: center;
}

.styles_accountBloc__-c8Wi .styles_header__PrKZ->span {
  font-family: Circular Std;
  font-style: normal;
  font-weight: normal;
  font-size: 1.8824rem;
  color: rgba(32, 32, 32, 1);
}

.styles_form__lOLWF {
  padding-top: 1.7647rem;
}

.styles_form__lOLWF>form>* {
  margin-top: 1.5rem;
}

.styles_info__2Icev {
  width: 100%;
  height: 3.5294rem;
  display: inline-flex;
  align-items: center;
  background-color: #f6f6f6;
  border-radius: 12px;
  border: 1px solid #e4e4e4;
  padding-left: 1.1rem;
  padding-right: 1.1rem;
}

.styles_info__2Icev>span {
  padding-left: 0.8rem;
  font-family: Circular Std Book;
  font-style: normal;
  font-weight: normal;
  font-size: 0.8824rem;
  color: rgba(72, 72, 72, 1);
}

.styles_formPasswordField__iqu7f>input {
  padding-right: 3.2rem;
}

.styles_passwordHideIcon__1VjIP {
  position: absolute;
  right: 4rem;
  margin-top: -2.36rem;
}

.styles_locationPicker__1pjhc {
  width: 100%;
}

.styles_locationPicker__1pjhc>span[class~='label'] {
  opacity: 0.7;
  overflow: visible;
  white-space: nowrap;
  text-align: left;
  font-family: Circular Std Book;
  font-weight: normal;
  font-size: 0.8889rem;
  color: rgba(32, 32, 32, 1);
}

.styles_locationPicker__1pjhc>div[class~='box'] {
  max-height: 200px;
  overflow-y: scroll;
  border-radius: 5px;
  background-color: #f6f6f6;
  padding: 5px;
  margin-top: 5px;
}

.styles_locationPicker__1pjhc>div[class~='box']>div {
  padding: 5px;
}

.styles_locationPicker__1pjhc>div[class~='box']>div>label {
  margin-left: 10px;
}

.styles_form__lOLWF>form>.styles_saveButton__kbWxC {
  margin-top: 3rem;
  height: 3.5rem;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: linear-gradient(302.39deg, #084edd -18.61%, #489ff9 113.13%);
  border: 0px;
  border-radius: 1.7778rem;
  overflow: hidden;
}

.styles_saveButton__kbWxC>span {
  color: white;
}
/*
 * @author Oleg Khalidov <brooth@gmail.com>.
 * -----------------------------------------------
 * Freelance software development:
 * Upwork: https://www.upwork.com/freelancers/~01d93e90d5b37c48d2
 */

.styles_list__rhLsh {
  width: 100%;
  margin-top: 1.9rem;
}

.styles_list__rhLsh > .styles_row___hM7G > div {
  margin-bottom: 1.9rem;
}

.styles_addButton__2qjNe {
  margin-left: 1.8rem;
  width: 7rem;
  height: 42px;
  background-color: black;
}
.styles_addButton__2qjNe:hover {
  background-color: rgba(0, 0, 0, 0.678);
}
.styles_addButton__2qjNe > span {
  color: white;
}

/*
 * @author Oleg Khalidov <brooth@gmail.com>.
 * -----------------------------------------------
 * Freelance software development:
 * Upwork: https://www.upwork.com/freelancers/~01d93e90d5b37c48d2
 */
.styles_container__LRkKs {
  width: 100vw;
  min-height: 100vh;
}

.styles_page__3DN4o {
  display: flex;
  flex-direction: row;
}

.styles_content__OpQJ7 {
  width: 100%;
  background-color: white;
  padding: 1.8rem 8rem 1.8rem 8rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: calc(100vh - 80px);
  overflow-y: scroll;
}

@media only screen and (max-width: 1526px) {
  .styles_content__OpQJ7 {
    padding: 1.8rem 7.9rem 1.8rem 7.9rem;
  }
}

@media only screen and (max-width: 1226px) {
  .styles_content__OpQJ7 {
    padding: 1.8rem 2.9rem 1.8rem 2.9rem;
  }
}

.styles_modeToggle__12wJO {
  margin-bottom: 1.1765rem;
}

.styles_notFoundContainer__1evuy {
  width: 100%;
  height: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.styles_notFoundContainer__1evuy > span {
  font-family: Circular Std Bold;
  font-style: normal;
  font-weight: normal;
  font-size: 5rem;
  color: rgba(32, 32, 32, 1);
}

.styles_reviews__18exi {
  width: 100%;
  max-width: 1100px;
  display: flex;
  flex-direction: column;
}

.styles_reviews__18exi .styles_totalReviewsStats__1bkN_ {
  margin-top: 50px;
}
.styles_reviews__18exi .styles_totalReviewsStats__1bkN_ > h1 {
  font-family: Poppins;
  font-style: normal;
  font-weight: bold;
  font-size: 22px;
  line-height: 32px;
  letter-spacing: 0.04em;
  color: #202020;
}

.styles_reviews__18exi .styles_totalReviewsStats__1bkN_ > h3 {
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 22px;
  letter-spacing: 0.04em;
  color: #5e5f60;
}

.styles_reviews__18exi .styles_totalReviewsStats__1bkN_ > h3 > b {
  font-weight: bold;
}

