/*
 * @author Oleg Khalidov <brooth@gmail.com>.
 * -----------------------------------------------
 * Freelance software development:
 * Upwork: https://www.upwork.com/freelancers/~01d93e90d5b37c48d2
 */
.formInputField {
  width: 100%;
}

.header {
  display: flex;
  justify-content: space-between;
  margin-bottom: 0.6111rem;
}

.label {
  opacity: 0.7;
  overflow: visible;
  white-space: nowrap;
  text-align: left;
  font-family: Circular Std Book;
  font-weight: normal;
  font-size: 0.8889rem;
  color: rgba(32, 32, 32, 1);
}

.error {
  text-align: right;
  font-family: Circular Std Book;
  font-size: 0.8889rem;
  color: rgb(212, 40, 40);
}

.control {
  padding: 1.7rem 1.3333rem;
  width: 100%;
  background-color: #f6f6f6;
  border: 0px;
  border-radius: 0.6667rem;
  white-space: nowrap;
  text-align: left;
  font-family: Circular Std;
  font-style: normal;
  font-weight: normal;
  font-size: 1rem;
  color: rgba(32, 32, 32, 1);
}

textarea.control {
  padding-top: 1rem;
  white-space: normal;
}

select.control,
select.control ::before,
select.control::after {
  box-sizing: border-box;
}
select.control {
  appearance: none;
}
