/*
 * @author Oleg Khalidov <brooth@gmail.com>.
 * -----------------------------------------------
 * Freelance software development:
 * Upwork: https://www.upwork.com/freelancers/~01d93e90d5b37c48d2
 */

.list {
  width: 100%;
  margin-top: 1.9rem;
}

.list>.row>div {
  margin-bottom: 14px;
}

.list>.row>div>div {
  padding: 25px;
  min-height: 0px;
}

.list>.row>div>div>div:first-child{
  padding-bottom: 16px;
}

.list>.row>div>div>div>div {
  width: 100%;
}

.list>.row>div>div>div>div>div {
  width: 100%;
}

.list>.row>div>div>div>div>div>p[class="title"] {
  margin-bottom: 7px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  width: 100%;
}

.list>.row>div>div>div>div>div>p[class="subtitle"] {
  margin-left: 2px;
  padding-top: 6px;
}

.list .icon {
  margin: 1px 10px 0 0;
  font-size: 18px;
  color: #656565;
}

.addButton {
  margin-left: 1.8rem;
  height: 42px;
  background-color: #00c455;
}

.addButton:hover {
  background-color: #00c455cb;
}

.addButton:active {
  background-color: #00c455;
}

.addButton>span {
  color: white;
}