/*
 * @author Oleg Khalidov <brooth@gmail.com>.
 * -----------------------------------------------
 * Freelance software development:
 * Upwork: https://www.upwork.com/freelancers/~01d93e90d5b37c48d2
 */

.topbarAction {
  height: 40px;
  width: 40px;
  padding: 0px;
  border: 1px solid #d2d2d2;
}
.topbarAction > span {
  display: none;
}
.logoutButton {
  height: 40px;
  border: 1px solid #d2d2d2;
}
.logoutButton > span {
  margin-left: 1.1111rem;
}
