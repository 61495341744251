/*
 * @author Oleg Khalidov <brooth@gmail.com>.
 * -----------------------------------------------
 * Freelance software development:
 * Upwork: https://www.upwork.com/freelancers/~01d93e90d5b37c48d2
 */

.list {
  width: 100%;
  margin-top: 1.9rem;
}

.list > .row > div {
  margin-bottom: 1.9rem;
}

.addLocationButton {
  margin-left: 1.8rem;
  width: 11.5882rem;
  height: 42px;
  background-color: #00c455;
}
.addLocationButton:hover {
  background-color: #00c455cb;
}
.addLocationButton:active {
  background-color: #00c455;
}
.addLocationButton > span {
  color: white;
}
