/*
 * @author Oleg Khalidov <brooth@gmail.com>.
 * -----------------------------------------------
 * Freelance software development:
 * Upwork: https://www.upwork.com/freelancers/~01d93e90d5b37c48d2
 */

.list {
  width: 100%;
  margin-top: 1.9rem;
}

.list>.row>div {
  margin-bottom: 1.9rem;
}

.title {
  font-size: 15px;
  text-align: left;
  font-family: Circular Std Bold;
  font-style: normal;
  font-weight: normal;
  color: rgba(32, 32, 32, 1);
}

.subtitle {
  margin: 10px 0px 0px 23px;
  text-align: left;
  font-family: Circular Std Book;
  font-style: normal;
  font-weight: normal;
  font-size: 0.9rem;
  color: #7d7d7d;
  letter-spacing: 0.1px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.addDataFilterButton {
  margin-left: 1.8rem;
  width: 12rem;
  height: 42px;
  background-color: black;
}

.addDataFilterButton:hover {
  background-color: rgba(0, 0, 0, 0.678);
}

.addDataFilterButton>span {
  color: white;
}

.dropzone {
  padding: 8px 0px 0px 0px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.dropzone>p {
  margin-top: 18px;
  font-family: Circular Std;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  color: #9d9b9b;
}