/*
 * @author Oleg Khalidov <brooth@gmail.com>.
 * -----------------------------------------------
 * Freelance software development:
 * Upwork: https://www.upwork.com/freelancers/~01d93e90d5b37c48d2
 */

.videoCampaignEditor {
  width: 40rem;
  padding: 3.3rem 2.7rem 2.7rem 2.7rem;
}

.videoCampaignEditor .header {
  width: 100%;
  height: 1.8824rem;
  display: inline-flex;
  justify-content: space-between;
  align-items: center;
}

.videoCampaignEditor .header>span {
  font-family: Circular Std;
  font-style: normal;
  font-weight: normal;
  font-size: 1.8824rem;
  color: rgba(32, 32, 32, 1);
}

.form {
  padding-top: 1.7647rem;
}

.form>form>* {
  margin-top: 1.5rem;
}

.form>form [class*="rbt"] {
  border: none;
}

.form .fieldWithRightIcon>input {
  padding-right: 3.2rem;
}

.form>form>.dateTimePicker div[class='rdtPicker'] {
  bottom: 50px;
  left: 110px;
  box-shadow: 3px 1px 4px rgb(0 0 0 / 10%);
}

.form>form>.addQuestionButton {
  margin: 10px 0 0 auto;
  align-items: self-end;
  justify-content: flex-end;
  background-color: #dbdbff;
  color: #3636a5;
  width: min-content;
  border-radius: 5px;
  padding: 7px 10px;
}

.form>form>.addQuestionButton>span {
  font-size: 13px !important;
}

.form>form>.saveButton {
  margin-top: 28px;
  height: 45px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: linear-gradient(302.39deg, #084edd -18.61%, #489ff9 113.13%);
  border: 0px;
  border-radius: 1.7778rem;
  overflow: hidden;
}

.saveButton>span {
  color: white;
}