/*
 * @author Oleg Khalidov <brooth@gmail.com>.
 * -----------------------------------------------
 * Freelance software development:
 * Upwork: https://www.upwork.com/freelancers/~01d93e90d5b37c48d2
 */

@media (min-width: 1200px) {
  .container {
    position: relative !important;
    top: 0px !important;
    left: 0px !important;
    border-radius: 0px !important;
    background-color: #f4f5f8 !important;
    box-shadow: none !important;
  }
}

.container {
  position: absolute;
  top: 15px;
  left: 15px;
  z-index: 990;
  background-color: #fff;
  align-items: flex-start;
  border-radius: 10px;
  box-shadow: none;
  border: transparent 1px solid;
  padding: 5px;
}

.container[data-expanded=true] {
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
  background-color: #f8f9fa;
  border: #f4f5f8 1px solid;
}

.container > div[class*='navbar-collapse'],
.container > div[class*='navbar-collapse'] > div {
  height: 100%;
  align-items: flex-start;
}

/* TOFIX: hide shadow when collapsed */
.container:not(:has(button)) {
  margin: 100px !important;
  border-color: red !important;
}

.container > button {
  font-size: 16px;
}

.item {
  margin: 6px 0px 6px 0px;
  width: 100%;
  min-width: 210px;
  height: 3.2941rem;
  background-color: transparent;
  border-radius: 9999px;
  display: flex;
  align-items: center;
  overflow: hidden;
  padding: 5px 20px;
  cursor: pointer;
  color: #5e5f60;
}

.item > span {
  color: #5e5f60;
  font-family: "Circular Std", Helvetica;
  font-size: 1.1111rem;
  font-style: normal;
  font-weight: normal;
  margin-left: 0.8rem;
}

.item[data-active="true"] {
  background-color: white;
}

.item[data-active="true"] > span {
  color: #084edd;
}
