.loginConteiner {
  background-color: rgba(0, 0, 0, 0.1);
  background-blend-mode: multiply;
  background-image: url("images/auth-bg.png");
  background-size: cover;
  min-height: 72rem;
}

.loginConteiner .header {
  background-color: #C6C9CD;
  border-bottom: 1px #d8dadd solid;
}

.formBloc {
  position: absolute;
  top: 8.6471rem;
  right: 8.3529rem;
  width: 39rem;
}

.formTitle {
  padding-top: 2.4444rem;
}

.formSubtitle {
  padding-top: 0rem;
}

.form {
  padding-left: 3.1111rem;
  padding-right: 3.1111rem;
  padding-top: 1.4111rem;
}

.formInputField {
  padding-top: 1.5rem;
}

.formPasswordField>input {
  padding-right: 2.9rem;
}

.passwordHideIcon {
  position: absolute;
  right: 4.3rem;
  margin-top: -2.36rem;
}

.button {
  margin-top: 3rem;
  height: 3.5rem;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: linear-gradient(302.39deg, #084edd -18.61%, #489ff9 113.13%);
  border: 0px;
  border-radius: 1.7778rem;
  overflow: hidden;
}

.button>span {
  color: white;
}

.formCopyright {
  opacity: 0.6;
  margin-top: 6.1111rem;
  margin-bottom: 1.6667rem;
  overflow: visible;
  white-space: nowrap;
  text-align: left;
  font-family: Circular Std Book;
  font-style: normal;
  font-weight: normal;
  font-size: 0.8889rem;
  color: rgba(28, 28, 28, 1);
}