/*
 * @author Oleg Khalidov <brooth@gmail.com>.
 * -----------------------------------------------
 * Freelance software development:
 * Upwork: https://www.upwork.com/freelancers/~01d93e90d5b37c48d2
 */
.component {
  width: 100%;
}

.header {
  display: flex;
  justify-content: space-between;
  margin-bottom: 0.6111rem;
}

.label {
  opacity: 0.7;
  overflow: visible;
  white-space: nowrap;
  text-align: left;
  font-family: Circular Std Book;
  font-weight: normal;
  font-size: 0.8889rem;
  color: rgba(32, 32, 32, 1);
}

.error {
  text-align: right;
  font-family: Circular Std Book;
  font-size: 0.8889rem;
  color: rgb(212, 40, 40);
}

.control {
  font-family: Circular Std Book;
  font-size: 0.8889rem;
  color: rgba(32, 32, 32, 1);
}
.control > input {
  padding: 1.7rem 1.3333rem;
  width: 100%;
  background-color: #f6f6f6;
  border: 0px;
  border-radius: 0.6667rem;
  white-space: nowrap;
  text-align: left;
  font-family: Circular Std;
  font-style: normal;
  font-weight: normal;
  font-size: 1rem;
  color: rgba(32, 32, 32, 1);
}
.control > input[readonly] {
  background-color: #f6f6f6;
}
.control > input[disabled] {
  background-color: #e9ecef;
  color: #6c757d;
}
.control > input[disabled]::placeholder {
  color: #6c757d;
}

.control th[class="rdtPrev"],
.control th[class="rdtSwitch"],
.control th[class="rdtNext"] {
  padding: 5px;
  border-bottom: 1px solid #e5e5e5;
}
.control th[class="dow"] {
  margin-top: 5px;
  height: 25px;
  padding: 5px;
  border-radius: 0px;
  border: 0px none;
  border-top: 5px solid white;
}
.control td[class*="rdtDay"] {
  height: 25px;
  padding: 5px;
  border-radius: 0px;
  border: 0px none;
  text-align: center;
}
.control td[class*="rdtDay"]:nth-child(3) {
  font-weight: normal;
}

.control td[class*="rdtMonth"],
.control td[class*="rdtYear"] {
  padding: 5px;
  border-radius: 0px;
  border: 0px none;
  border-top: 5px solid white;
}

.control td[class*="rdtSwitch"] {
  cursor: pointer;
  padding: 7px;
  border: 1px solid #e5e5e5;
  border-radius: 10px;
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 26px;
  letter-spacing: 0.04em;
  color: #777777;
}
.control div[class="rdtTime"] table > tbody > tr > td {
  border: 0px none;
}

.control div[class*="rdtCounters"] {
  width: 100%;
  display: flex;
  justify-content: center;
}

.control *[class*="rdtCount"],
.control *[class*="rdtBtn"] {
  color: #646262;
  font-family: Circular Std;
  font-style: normal;
  font-weight: normal;
  font-size: 1rem;
  text-align: center;
}

.resetButton {
  position: absolute;
  right: 3.7rem;
  margin-top: -2.2rem;
}
