/*
 * @author Oleg Khalidov <brooth@gmail.com>.
 * -----------------------------------------------
 * Freelance software development:
 * Upwork: https://www.upwork.com/freelancers/~01d93e90d5b37c48d2
 */

.svg {
  fill: rgba(32, 32, 32, 1);
  margin-right: 5px;
}
.path {
  overflow: visible;
  position: absolute;
  width: 24px;
  height: 24px;
  left: 0px;
  top: 0px;
  transform: matrix(1, 0, 0, 1, 0, 0);
}
