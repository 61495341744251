/*
 * @author Oleg Khalidov <brooth@gmail.com>.
 * -----------------------------------------------
 * Freelance software development:
 * Upwork: https://www.upwork.com/freelancers/~01d93e90d5b37c48d2
 */

.header {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 2.5rem;
}
.header > .title {
  font-family: Circular Std Bold;
  font-style: normal;
  font-weight: normal;
  font-size: 2.8889rem;
  color: rgba(32, 32, 32, 1);
  margin-right: 2.3rem;
}
.header > .searchingFor {
  display: inline-flex;
  align-items: center;
  margin-bottom: 2.3rem;
}
.header > .searchingFor > span {
  font-family: Circular Std Black;
  font-style: normal;
  font-weight: normal;
  font-size: 1.8824rem;
  color: rgba(32, 32, 32, 1);
  margin-left: 1.2rem;
}

.addLocationButton {
  margin-left: 4rem;
  width: 11.5882rem;
  height: 3.1111rem;
  background-color: #00c455;
}
.addLocationButton:hover {
  background-color: #00c455cb;
}
.addLocationButton:active {
  background-color: #00c455;
}
.addLocationButton > span {
  color: white;
}

.addClientButton {
  margin-left: 1.8rem;
  width: 10.1176rem;
  height: 3.1111rem;
  background-color: black;
}
.addClientButton:hover {
  background-color: rgba(0, 0, 0, 0.678);
}
.addClientButton > span {
  color: white;
}
