/*
 * @author Oleg Khalidov <brooth@gmail.com>.
 * -----------------------------------------------
 * Freelance software development:
 * Upwork: https://www.upwork.com/freelancers/~01d93e90d5b37c48d2
 */

.accountBloc {
  width: 39.6471rem;
  padding: 3.3rem 2.7rem 2.7rem 2.7rem;
}

.accountBloc .header {
  width: 100%;
  height: 1.8824rem;
  display: inline-flex;
  justify-content: space-between;
  align-items: center;
}

.accountBloc .header>span {
  font-family: Circular Std;
  font-style: normal;
  font-weight: normal;
  font-size: 1.8824rem;
  color: rgba(32, 32, 32, 1);
}

.form {
  padding-top: 1.7647rem;
}

.form>form>* {
  margin-top: 1.5rem;
}

.info {
  width: 100%;
  height: 3.5294rem;
  display: inline-flex;
  align-items: center;
  background-color: #f6f6f6;
  border-radius: 12px;
  border: 1px solid #e4e4e4;
  padding-left: 1.1rem;
  padding-right: 1.1rem;
}

.info>span {
  padding-left: 0.8rem;
  font-family: Circular Std Book;
  font-style: normal;
  font-weight: normal;
  font-size: 0.8824rem;
  color: rgba(72, 72, 72, 1);
}

.formPasswordField>input {
  padding-right: 3.2rem;
}

.passwordHideIcon {
  position: absolute;
  right: 4rem;
  margin-top: -2.36rem;
}

.locationPicker {
  width: 100%;
}

.locationPicker>span[class~='label'] {
  opacity: 0.7;
  overflow: visible;
  white-space: nowrap;
  text-align: left;
  font-family: Circular Std Book;
  font-weight: normal;
  font-size: 0.8889rem;
  color: rgba(32, 32, 32, 1);
}

.locationPicker>div[class~='box'] {
  max-height: 200px;
  overflow-y: scroll;
  border-radius: 5px;
  background-color: #f6f6f6;
  padding: 5px;
  margin-top: 5px;
}

.locationPicker>div[class~='box']>div {
  padding: 5px;
}

.locationPicker>div[class~='box']>div>label {
  margin-left: 10px;
}

.form>form>.saveButton {
  margin-top: 3rem;
  height: 3.5rem;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: linear-gradient(302.39deg, #084edd -18.61%, #489ff9 113.13%);
  border: 0px;
  border-radius: 1.7778rem;
  overflow: hidden;
}

.saveButton>span {
  color: white;
}