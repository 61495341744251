/*
 * @author Oleg Khalidov <brooth@gmail.com>.
 * -----------------------------------------------
 * Freelance software development:
 * Upwork: https://www.upwork.com/freelancers/~01d93e90d5b37c48d2
 */

.bloc {
  width: 39.6471rem;
  padding: 3.3rem 2.7rem 2.7rem 2.7rem;
}

.bloc .header {
  width: 100%;
  height: 1.8824rem;
  display: inline-flex;
  justify-content: space-between;
  align-items: center;
}

.bloc .header>span {
  font-family: Circular Std;
  font-style: normal;
  font-weight: normal;
  font-size: 1.8824rem;
  color: rgba(32, 32, 32, 1);
}

.caruselDots {
  margin: 40px 40px 0px 40px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  flex-wrap: wrap;
}

.caruselDot {
  margin: 0px 5px 5px 0px;
  width: 24px;
  height: 24px;
  border: 1px solid #d5d5d5;
  border-radius: 99px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.caruselDotActive {
  border: 1px solid #a7b6ef;
  background-color: #e7ebfe;
}

.dropzone {
  height: 605px;
  width: 100%;
  border: 3px dashed #b4b4b4;
  border-radius: 20px;
  margin: 30px 0px;
  padding: 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.dropzone>p {
  margin-top: 25px;
  font-family: Circular Std;
  font-style: normal;
  font-weight: normal;
  font-size: 25px;
  color: #9d9b9b;
}

.form {
  padding-top: 0rem;
  margin-bottom: 2rem;
}

.form[class~="visible"] {
  display: flex;
}

.form[class~="hidden"] {
  display: none;
}

.form>form>* {
  margin-top: 1.5rem;
}

.form>form>.destinations {
  height: 220px;
  margin-bottom: 40px;
}

.form>form>.destinations>[name="destinations"] {
  height: 100%;
  padding: 0px;
  font-size: 13px;
  overflow: scroll;
}

.form>form>.destinations>textarea[name="destinations"] {
  padding: 10px 15px;
  line-height: 27.5px;
}

.sendStateItem {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 4px 15px;
}

.sendStateItem:first-child {
  margin-top: 15px;
}

.sendStateItem:last-child {
  margin-bottom: 15px;
}

.sendStateItemError {
  background-color: rgba(255, 0, 0, 0.11);
}

.sendStateItem span:first-child {
  margin-right: 50px;
}

.sendStateItem span:last-child {
  white-space: normal;
  text-align: right;
}

.form>form>.dateTimePicker div[class='rdtPicker'] {
  bottom: 50px;
  left: 110px;
  box-shadow: 3px 1px 4px rgb(0 0 0 / 10%);
}

.sendButton {
  margin-top: 0.8rem;
  height: 3.5rem;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: linear-gradient(302.39deg, #084edd -18.61%, #489ff9 113.13%);
  border: 0px;
  border-radius: 1.7778rem;
  overflow: hidden;
}

.sendButton>span {
  color: white;
}