/*
 * @author Oleg Khalidov <brooth@gmail.com>.
 * -----------------------------------------------
 * Freelance software development:
 * Upwork: https://www.upwork.com/freelancers/~01d93e90d5b37c48d2
 */

.searchResult {
  width: fit-content;
  background-color: #f5f6f9;
  padding: 1.1765rem 1.8824rem;
  border-radius: 16px;
  border: 0.1px solid #c5ccdc;
  margin-top: 2rem;
  margin-bottom: 2rem;
  align-self: center;
}
.searchResult > span {
  font-family: Circular Std Book;
  font-style: normal;
  font-weight: normal;
  font-size: 1.0588rem;
  color: rgba(32, 32, 32, 1);
}
