/*
 * @author Oleg Khalidov <brooth@gmail.com>.
 * -----------------------------------------------
 * Freelance software development:
 * Upwork: https://www.upwork.com/freelancers/~01d93e90d5b37c48d2
 */

.backLoadingIndicator {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
