/*
 * @author Oleg Khalidov <brooth@gmail.com>.
 * -----------------------------------------------
 * Freelance software development:
 * Upwork: https://www.upwork.com/freelancers/~01d93e90d5b37c48d2
 */
.header {
  width: 100%;
  height: 5.5556rem;
  border-bottom: 1px #CACBCD solid;
  background-color: white;
  display: inline-flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 8.5rem;
  padding-right: 8.5rem;
}

.header > img {
  height: 60px;
}

.header > .buttons {
  display: inline-flex;
  justify-content: space-between;
  align-items: center;
}
.header > .buttons > * {
  margin-left: 10px;
}