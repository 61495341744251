/*
 * @author Oleg Khalidov <brooth@gmail.com>.
 * -----------------------------------------------
 * Freelance software development:
 * Upwork: https://www.upwork.com/freelancers/~01d93e90d5b37c48d2
 */

.list {
  width: 100%;
  margin-top: 1.9rem;
}

.list > .row > div {
  margin-bottom: 1.9rem;
}

.addButton {
  margin-left: 1.8rem;
  width: 7rem;
  height: 42px;
  background-color: black;
}
.addButton:hover {
  background-color: rgba(0, 0, 0, 0.678);
}
.addButton > span {
  color: white;
}
