/*
 * @author Oleg Khalidov <brooth@gmail.com>.
 * -----------------------------------------------
 * Freelance software development:
 * Upwork: https://www.upwork.com/freelancers/~01d93e90d5b37c48d2
 */

.error {
  font-family: Circular Std Book;
  font-size: 0.8889rem;
  color: rgb(212, 40, 40);
  text-align: left;
}
