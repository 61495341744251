/*
 * @author Oleg Khalidov <brooth@gmail.com>.
 * -----------------------------------------------
 * Freelance software development:
 * Upwork: https://www.upwork.com/freelancers/~01d93e90d5b37c48d2
 */
 .formSelectField {
  width: 100%;
}

.header {
  display: flex;
  justify-content: space-between;
  margin-bottom: 0.6111rem;
}

.label {
  opacity: 0.7;
  overflow: visible;
  white-space: nowrap;
  text-align: left;
  font-family: Circular Std Book;
  font-weight: normal;
  font-size: 0.8889rem;
  color: rgba(32, 32, 32, 1);
}

.error {
  text-align: right;
  font-family: Circular Std Book;
  font-size: 0.8889rem;
  color: rgb(212, 40, 40);
}

.control {
  height: 3.4rem;
  padding: 0rem 2.9rem 0rem 1.4rem;
  width: 100%;
  background: none;
  background-color: #f6f6f6;
  border: 0px;
  border-radius: 0.6667rem;
  white-space: nowrap;
  text-align: left;
  font-family: Circular Std;
  font-style: normal;
  font-weight: normal;
  font-size: 1rem;
  color: rgba(32, 32, 32, 1);
  box-sizing: border-box;
}
.control:invalid {
  color: #7E868D;
}

.arrowIcon {
  position: relative;
  top: -1.9rem;
  right: 1.2rem;
  float: right;
  pointer-events: none;
}
