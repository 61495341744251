/*
 * @author Oleg Khalidov <brooth@gmail.com>.
 * -----------------------------------------------
 * Freelance software development:
 * Upwork: https://www.upwork.com/freelancers/~01d93e90d5b37c48d2
 */

.body {
  display: flex;
  flex-direction: row;
  width: 100%;
  gap: 20px;
}

.leftPanel {
  flex: 1.5;
  display: flex;
  flex-direction: column;
  height: fit-content;
  padding: 20px;
  background-color: #fafafa;
  border-radius: 3px;
}

.leftPanel>.searchInput {
  margin: 0 0 20px 0;
  width: 100%;
  border: 1px #ccc solid;
  border-radius: 5px;
  padding: 10px;
}

.leftPanel>.reply {
  margin: 5px 0px;
  padding: 10px 20px;
  border-radius: 4px;
  width: 100%;
  background-color: #ECF2FD;
  color: #0B0A0A;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
}

.leftPanel>.reply>input {
  margin: 0px 15px 0px 0;
}

.leftPanel>.reply>div {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 5px;
}

.leftPanel>.reply>div>span[class="title"] {
  font-size: 14px;
}

.leftPanel>.reply>div>span[class="subtitle"] {
  font-size: 12px;
  color: #888;
}

.leftPanel>.reply>span {
  font-size: 12px;
  color: #888;
}

.leftPanel>.seenReply {
  background-color: white;
  color: #474747;
}

.leftPanel>.selectedReply {
  background-color: #3961F5;
  color: white;
}

.leftPanel>.selectedReply>span,
.leftPanel>.selectedReply>div>span[class="subtitle"] {
  color: #aaa;
}


.rightPanel {
  flex: 3;
  display: flex;
  flex-direction: column;
  padding: 20px;
  border-radius: 3px;
  background-color: transparent;
}

.rightPanel[class~="selected"] {
  background-color: #f8f8f8;
}

.rightPanel>h3 {
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 5px;
}

.rightPanel>h4 {
  font-size: 14px;
  font-weight: normal;
  margin-bottom: 10px;
  color: #777;
}

.rightPanel .question {
  margin-top: 30px;
}

.rightPanel .question>h3 {
  font-size: 15px;
  font-weight: 500;
}

.rightPanel .question>p {
  font-size: 13px;
  color: #777;
  margin-top: 10px;
}

.rightPanel .question .record {
  margin-top: 10px;
  display: flex;
  flex-direction: row;
  border: 1px #eee solid;
  border-radius: 3px;
  align-items: center;
  padding: 10px 20px 10px 15px;
  gap: 10px;
  background-color: white;
}

.rightPanel .question .record img {
  height: 80px;
  border-radius: 3px;
  border: 1px solid #eee;
  margin-right: auto;
}

.rightPanel .question .record span {
  margin-right: auto;
}

.rightPanel .question .record a {
  border-radius: 3px;
  font-size: 13px;
  padding: 5px 10px;
  text-decoration: none;
}

.rightPanel .question .record a[class="preview"] {
  border: 1px #2A62FE solid;
  color: #2A62FE;
  background-color: white;
}

.rightPanel .question .record a[class="preview"]>svg {
  margin-top: -2px;
  margin-left: 5px;
}

.rightPanel .question .record a[class="download"] {
  border: 1px #2A62FE solid;
  background-color: #2A62FE;
  color: white;
}