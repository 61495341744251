/*
 * @author Oleg Khalidov <brooth@gmail.com>.
 * -----------------------------------------------
 * Freelance software development:
 * Upwork: https://www.upwork.com/freelancers/~01d93e90d5b37c48d2
 */
.toastContainer {
  width: auto;
  min-width: 150px;
}
.toast {
  font-family: Circular Std Medium;
  font-style: normal;
  font-weight: normal;
  font-size: 1rem;
  color: rgba(32, 32, 32, 1);
  border-radius: 99px;
  min-height: 30px;
}
.toast [class="Toastify__toast-body"] {
  text-align: center;
}
.toast [class*="Toastify__close-button "] {
  align-self: center;
  padding: 0px 8px 1px 8px;
}

.toast[class*="Toastify__toast--success"] {
  background-color: rgba(6, 180, 81, 0.979);
  color: white;
}

.toast[class*="Toastify__toast--info"] {
  background-color: rgba(6, 110, 180, 0.979);
  color: white;
}
