/*
 * @author Oleg Khalidov <brooth@gmail.com>.
 * -----------------------------------------------
 * Freelance software development:
 * Upwork: https://www.upwork.com/freelancers/~01d93e90d5b37c48d2
 */

.container {
  width: 39.6471rem;
  padding: 3.3rem 2.7rem 2.7rem 2.7rem;
}

.container .header {
  width: 100%;
  height: 1.8824rem;
  display: inline-flex;
  justify-content: space-between;
  align-items: center;
}

.container .header > span {
  font-family: Circular Std;
  font-style: normal;
  font-weight: normal;
  font-size: 1.8824rem;
  color: rgba(32, 32, 32, 1);
}

.form {
  padding-top: 0rem;
}

.form > form > * {
  margin-top: 1.5rem;
}

.form > form > .formRow {
  margin-top: 10px;
}
.form > form > .formRow > div {
  width: auto;
  flex: 1;
  margin: 10px 15px 0px 15px;
}
.form > form > .formRow > div > input {
  padding: 1.5rem 1rem;
}
.form > form > div > textarea {
  overflow-x: scroll;
  white-space: pre;
}

.form > form > .taglist > div[class*="react-tagsinput"] {
  margin-top: 0.6rem;
  padding: 0.7rem 1rem 0.4rem 1rem;
  width: 100%;
  background-color: #f6f6f6;
  border: 0px;
  border-radius: 0.6667rem;
}

.form
  > form
  > .taglist
  > div[class*="react-tagsinput"]
  input[class="react-tagsinput-input"] {
  width: 160px;
}

.form
  > form
  > .taglist
  > div[class*="react-tagsinput"]
  span[class="react-tagsinput-tag"] {
  white-space: nowrap;
  text-align: left;
  font-family: Circular Std;
  font-style: normal;
  font-weight: normal;
  font-size: 1rem;
  color: white;
  background-color: rgb(32, 32, 32, 1);
  border: 1px solid rgb(32, 32, 32, 1);
  border-radius: 5px;
}

.form
  > form
  > .taglist
  > div[class*="react-tagsinput"]
  a[class="react-tagsinput-remove"] {
  color: white;
}

.form > form > .taglist > span[class="label"] {
  opacity: 0.7;
  overflow: visible;
  white-space: nowrap;
  text-align: left;
  font-family: Circular Std Book;
  font-weight: normal;
  font-size: 0.8889rem;
  color: rgba(32, 32, 32, 1);
}

.info {
  width: 100%;
  height: 3.5294rem;
  display: inline-flex;
  align-items: center;
  background-color: #f6f6f6;
  border-radius: 12px;
  border: 1px solid #e4e4e4;
  padding-left: 1.1rem;
  padding-right: 1.1rem;
}
.info > span {
  padding-left: 0.8rem;
  font-family: Circular Std Book;
  font-style: normal;
  font-weight: normal;
  font-size: 0.8824rem;
  color: rgba(72, 72, 72, 1);
}

.form > form > .saveButton {
  margin-top: 3rem;
  height: 3.5rem;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: linear-gradient(302.39deg, #084edd -18.61%, #489ff9 113.13%);
  border: 0px;
  border-radius: 1.7778rem;
  overflow: hidden;
}
.saveButton > span {
  color: white;
}