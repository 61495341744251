/*
 * @author Oleg Khalidov <brooth@gmail.com>.
 * -----------------------------------------------
 * Freelance software development:
 * Upwork: https://www.upwork.com/freelancers/~01d93e90d5b37c48d2
 */
 
.tagsinput > div {
  width: 100%;
}

.header {
  display: flex;
  justify-content: space-between;
  margin-bottom: 0.6111rem;
}
 
.label {
  opacity: 0.7;
  overflow: visible;
  white-space: nowrap;
  text-align: left;
  font-family: Circular Std Book;
  font-weight: normal;
  font-size: 0.8889rem;
  color: rgba(32, 32, 32, 1);
}

.error {
  text-align: right;
  font-family: Circular Std Book;
  font-size: 0.8889rem;
  color: rgb(212, 40, 40);
}

.tagsinput > div[class*="react-tagsinput"] {
  margin-top: 0.6rem;
  padding: 0.7rem 1rem 0.4rem 1rem;
  width: 100%;
  background-color: #f6f6f6;
  border: 0px;
  border-radius: 0.6667rem;
}

.tagsinput
  > div[class*="react-tagsinput"]
  input[class="react-tagsinput-input"] {
  width: 180px;
}

.tagsinput
  > div[class*="react-tagsinput"]
  span[class="react-tagsinput-tag"] {
  white-space: nowrap;
  text-align: left;
  font-family: Circular Std;
  font-style: normal;
  font-weight: normal;
  font-size: 1rem;
  color: white;
  background-color: rgb(53, 100, 187);
  border: 1px solid rgb(30, 88, 128);
  border-radius: 5px;
}

.tagsinput
  > div[class*="react-tagsinput"]
  a[class="react-tagsinput-remove"] {
  color: white;
}

.tagsinput > span[class="label"] {
  opacity: 0.7;
  overflow: visible;
  white-space: nowrap;
  text-align: left;
  font-family: Circular Std Book;
  font-weight: normal;
  font-size: 0.8889rem;
  color: rgba(32, 32, 32, 1);
}