/*
 * @author Oleg Khalidov <brooth@gmail.com>.
 * -----------------------------------------------
 * Freelance software development:
 * Upwork: https://www.upwork.com/freelancers/~01d93e90d5b37c48d2
 */

.formImage {
  width:  11.5477rem;
  height: 10.3074rem;
  margin-left: 12.3333rem;
  margin-right: 12.9523rem;
  margin-top: 4rem;
  overflow: visible;
}
