/*
 * @author Oleg Khalidov <brooth@gmail.com>.
 * -----------------------------------------------
 * Freelance software development:
 * Upwork: https://www.upwork.com/freelancers/~01d93e90d5b37c48d2
 */

table {
  width: 100%;
  margin-top: 1.9rem;
  border-collapse: separate;
  text-align: center;
}

table > thead {
  background-color: #f9fcfd;
}

table tr > * {
  vertical-align: middle;
}

table > tbody > tr > td:first-child {
  text-align: left;
  padding-left: 12px;
  padding-right: 12px;
  white-space: nowrap;
}
table > thead > tr > th {
  padding: 7px 7px;
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 26px;
  letter-spacing: 0.04em;
  color: #777777;
  border-top: 1px solid #e5e5e5;
  border-bottom: 1px solid #e5e5e5;
  border-right: 1px solid #e5e5e5;
}

table > thead > tr:nth-child(2) > th {
  border-top: 0px;
}

table > thead > tr:first-child > th:first-child {
  border-left: 1px solid #e5e5e5;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
}

table > thead > tr:first-child > th:last-child {
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
}

table > tbody > tr[class="spacer"] {
  height: 15px;
}

table > tbody > tr > td {
  padding: 14px 2px;
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 26px;
  letter-spacing: 0.04em;
  color: #333333;
  border-left: 1px solid #e5e5e5;
  border-top: 1px solid #e5e5e5;
  border-bottom: 1px solid #e5e5e5;
}

table > tbody > tr > td:first-child {
  border-left: 1px solid #e5e5e5;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
}

table > tbody > tr > td:last-child {
  border-right: 1px solid #e5e5e5;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
}

table > tbody > tr > td:nth-child(3) {
  font-weight: bold;
}

table > tbody > tr > td:nth-child(9),
table > tbody > tr > td:nth-child(10),
table > tbody > tr > td:nth-child(11) {
  background-color: #ffefe7;
}

table > tbody > tr > td > p[class="locationAddress"] {
  color: #777777;
  font-weight: normal;
  font-size: 12px;
}

.loadMoreButton {
  margin-top: 20px;
  margin-bottom: 50px;
  padding: 5px 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: linear-gradient(302.39deg, #084edd -18.61%, #489ff9 113.13%);
  border: 0px;
  border-radius: 1.7778rem;
  overflow: hidden;
}
.loadMoreButton:hover {
  opacity: 0.9;
}
.loadMoreButton:active {
  opacity: 1;
}
.loadMoreButton > span {
  letter-spacing: 0px;
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 28px;
  letter-spacing: 0.04em;
  color: rgba(255, 255, 255, 1);
  text-transform: uppercase;
}

.statusIcon {
  margin-left: 4px;
  margin-bottom: 2px;
  color: #c73d3d;
}

.bulkCampaignBloc {
  width: 39.6471rem;
  padding: 3.3rem 2.7rem 2.7rem 2.7rem;
}

.bulkCampaignBloc > div[class="header"] {
  width: 100%;
  height: 1.8824rem;
  display: inline-flex;
  justify-content: space-between;
  align-items: center;
}

.bulkCampaignBloc > div[class="header"] > span {
  font-family: Circular Std;
  font-style: normal;
  font-weight: normal;
  font-size: 1.8824rem;
  color: rgba(32, 32, 32, 1);
}
.bulkCampaignBloc > div[class="subheader"] {
  margin-top: 10px;
  align-self: flex-start;
  font-family: Circular Std;
  font-style: normal;
  font-weight: normal;
  font-size: 17px;
  color: rgb(128, 127, 127);
}
.bulkCampaignBloc > div[class="body"] {
  margin: 40px -10px 10px -10px;
  width: 100%;
  height: 40vh;
  padding: 0px;
  overflow: scroll;
  background-color: #f6f6f6;
  border: 0px;
  border-radius: 0.6667rem;
  white-space: nowrap;
  text-align: left;
  font-family: Circular Std;
  font-style: normal;
  font-weight: normal;
  font-size: 1rem;
  color: rgba(32, 32, 32, 1);
}