/*
 * @author Oleg Khalidov <brooth@gmail.com>.
 * -----------------------------------------------
 * Freelance software development:
 * Upwork: https://www.upwork.com/freelancers/~01d93e90d5b37c48d2
 */
.searchBar {
  flex: 1;
  width: auto;
  height: 3.1111rem;
  display: flex;
}
.control {
  margin-left: -1.3rem;
  padding: 0px 0px 0px 2.9rem;
  width: 18rem;
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  overflow: hidden;
  background-color: white;
  border: 1px solid #d2d2d2;
  border-radius: 99rem;
  white-space: nowrap;
  text-align: left;
  font-family: Circular Std Book;
  font-style: normal;
  font-weight: normal;
  font-size: 1rem;
  color: rgba(32, 32, 32, 1);
  transition: width 0.3s ease-in-out;
}
.control:not(:placeholder-shown),
.control:focus {
  width: 100%;
  transition: width 0.32s ease-in-out;
}
.searchIcon {
  position: relative;
  top: 1rem;
  left: 1.1rem;
  width: 1.4rem;
}
.closeIcon {
  display: none;
  position: relative;
  top: 1rem;
  right: 2.1rem;
  width: 1.4rem;
  cursor: pointer;
}
.control:not(:placeholder-shown) ~ .closeIcon,
.control:focus ~ .closeIcon {
  display: inline;
}

.searchButton {
  margin-left: 0.6rem;
  height: 100%;
  width: 8.0588rem;
  display: flex;
  justify-content: center;
  align-items: center;
  background: linear-gradient(302.39deg, #084edd -18.61%, #489ff9 113.13%);
  border: 0px;
  border-radius: 1.7778rem;
  overflow: hidden;
}
.searchButton:hover {
  opacity: 0.9;
}
.searchButton:active {
  opacity: 1;
}
.searchButton > span {
  letter-spacing: 0px;
  font-family: Circular Std;
  font-style: normal;
  font-weight: normal;
  font-size: 1rem;
  color: rgba(255, 255, 255, 1);
  text-transform: uppercase;
}
