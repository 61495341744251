/*
 * @author Oleg Khalidov <brooth@gmail.com>.
 * -----------------------------------------------
 * Freelance software development:
 * Upwork: https://www.upwork.com/freelancers/~01d93e90d5b37c48d2
 */

.backPrint {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.backPrint > [class*="title"] {
  font-family: Circular Std Bold;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  color: rgba(125, 125, 125, 1);
}

.backPrint > [class*="message"] {
  font-family: Circular Std;
  font-style: normal;
  font-weight: normal;
  font-size: 17px;
  color: rgba(125, 125, 125, 1);
  margin-top: 20px;
}

.backPrint > [class*="button"] {
  margin-top: 30px;
  border: 1px solid rgb(255, 186, 71);
  color:  rgb(255, 186, 71);
  padding: 8px 12px;
  font-size: 15px;
}