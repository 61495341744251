/*
 * @author Oleg Khalidov <brooth@gmail.com>.
 * -----------------------------------------------
 * Freelance software development:
 * Upwork: https://www.upwork.com/freelancers/~01d93e90d5b37c48d2
 */

.button {
  background-color: white;
  border-radius: 9999px;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  padding: 5px 17px;
  cursor: pointer;
}
.button:hover {
  opacity: 0.9;
}
.button:active {
  opacity: 1;
}
.disabled {
  cursor: none;
  pointer-events: none;
  opacity: 0.5;
}
.loading {
  animation: waves 60000s linear infinite forwards;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  cursor: none;
  pointer-events: none;
}

.text {
  user-select: none;
  color: var(--eerie-black-2);
  font-family: "Circular Std", Helvetica;
  font-size: 1.1111rem;
  font-style: normal;
  font-weight: normal;
  letter-spacing: 0px;
  margin-bottom: -1px;
  margin-left: 0.39rem;
  margin-right: 0.2778rem;
  white-space: nowrap;
}

@keyframes waves {
  0% {
    background-position: 0vw 0
  }
  100% {
    background-position: 1000000vw 0
  }
}