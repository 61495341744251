/*
 * @author Oleg Khalidov <brooth@gmail.com>.
 * -----------------------------------------------
 * Freelance software development:
 * Upwork: https://www.upwork.com/freelancers/~01d93e90d5b37c48d2
 */

.title {
  letter-spacing: 0rem;
  line-height: 3.2222rem;
  min-height: 2.4444rem;
  min-width: 16rem;
  white-space: nowrap;
  color: var(--eerie-black);
  font-family: "CircularStd Black-Regular", Helvetica;
  font-size: 2.3333rem;
  font-style: normal;
  font-weight: 400;
}
