/*
 * @author Oleg Khalidov <brooth@gmail.com>.
 * -----------------------------------------------
 * Freelance software development:
 * Upwork: https://www.upwork.com/freelancers/~01d93e90d5b37c48d2
 */

.listItem {
  padding: 2rem 1.6rem 1.6rem 1.6rem;
  width: 100%;
  height: 100%;
  min-height: 11.1rem;
  border: 1px solid #d1d1d1;
  border-radius: 1.2941rem;
  display: flex;
  flex-direction: column;
}

.listItem>.content {
  flex-grow: 1;
  padding-bottom: 1.7rem;
  width: 100%;
}

.listItem>.content [class="icon"] {
  margin: 0.1rem 0.5rem 0px 0rem;
}

.listItem>.content [class="title"] {
  text-align: left;
  font-family: Circular Std Bold;
  font-style: normal;
  font-weight: normal;
  font-size: 1.3rem;
  line-height: 1.45rem;
  color: rgba(32, 32, 32, 1);
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow-x: hidden;
}

.listItem>.content [class="subtitle"] {
  text-align: left;
  font-family: Circular Std Book;
  font-style: normal;
  font-weight: normal;
  font-size: 0.9rem;
  color: #7d7d7d;
  letter-spacing: 0.1px;
  padding-top: 10px;
}

.listItem>.buttons {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  height: 2.3rem;
}

.listItem>.buttons span {
  font-size: 0.9rem;
}

.listItem>.buttons>.edit {
  border: 1px solid #d1d1d1;
  flex: 1;
  margin-right: 0.7rem;
}

.listItem>.buttons>.remove {
  margin-left: 0.7rem;
  border: 1px solid #f8cece;
  color: #df2c2c;
  background-color: #fceded;
  flex: 1;
}