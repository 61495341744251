/*
 * @author Oleg Khalidov <brooth@gmail.com>.
 * -----------------------------------------------
 * Freelance software development:
 * Upwork: https://www.upwork.com/freelancers/~01d93e90d5b37c48d2
 */

.subtitle {
  color: var(--eerie-black);
  font-family: "CircularStd Book-Regular", Helvetica;
  font-size: 1.3333rem;
  font-style: normal;
  font-weight: 400;
  letter-spacing: 0rem;
  line-height: 2.8889rem;
  min-height: 1.4444rem;
  min-width: 23.7778rem;
  opacity: 0.7;
  text-align: center;
  white-space: nowrap;
}
