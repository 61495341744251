/*
 * @author Oleg Khalidov <brooth@gmail.com>.
 * -----------------------------------------------
 * Freelance software development:
 * Upwork: https://www.upwork.com/freelancers/~01d93e90d5b37c48d2
 */

.container {
  width: 100%;
  margin-top: 2.9rem;
  margin-left: 0.1rem;
  margin-right: 1.1rem;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.locationsDropdown > div:first-child > span:first-child {
  font-family: Poppins;
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 26px;
  display: flex;
  align-items: flex-end;
  letter-spacing: 0.04em;
  color: #202020;
}

.showRepliedDropdown {
  margin-left: 20px;
}

.selectedStars {
  width: auto;
  min-width: 300px;
  margin-left: 20px;
}

.selectedStars > div[class*='-container'] > nowrap > div[class*='-control'] > svg {
  margin-right: -7px;
}
.selectedStars > div[class*='-container'] > nowrap > div[class*='-control'] div[class*='-multiValue'] {
  border-radius: 10px;
  font-family: Poppins;
  font-style: normal;
  font-size: 12px;
}
.selectedStars > div[class*='-container'] > nowrap > div[class*='-control'] div[class*='-multiValue'] > div:nth-child(2) {
  border-radius: 10px;
  background-color: transparent;
}