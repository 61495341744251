/*
 * @author Oleg Khalidov <brooth@gmail.com>.
 * -----------------------------------------------
 * Freelance software development:
 * Upwork: https://www.upwork.com/freelancers/~01d93e90d5b37c48d2
 */
.container {
  width: 100vw;
  min-height: 100vh;
}

.page {
  display: flex;
  flex-direction: row;
}

.content {
  width: 100%;
  background-color: white;
  padding: 1.8rem 8rem 1.8rem 8rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: calc(100vh - 80px);
  overflow-y: scroll;
}

@media only screen and (max-width: 1526px) {
  .content {
    padding: 1.8rem 7.9rem 1.8rem 7.9rem;
  }
}

@media only screen and (max-width: 1226px) {
  .content {
    padding: 1.8rem 2.9rem 1.8rem 2.9rem;
  }
}

.modeToggle {
  margin-bottom: 1.1765rem;
}

.notFoundContainer {
  width: 100%;
  height: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.notFoundContainer > span {
  font-family: Circular Std Bold;
  font-style: normal;
  font-weight: normal;
  font-size: 5rem;
  color: rgba(32, 32, 32, 1);
}

.reviews {
  width: 100%;
  max-width: 1100px;
  display: flex;
  flex-direction: column;
}

.reviews .totalReviewsStats {
  margin-top: 50px;
}
.reviews .totalReviewsStats > h1 {
  font-family: Poppins;
  font-style: normal;
  font-weight: bold;
  font-size: 22px;
  line-height: 32px;
  letter-spacing: 0.04em;
  color: #202020;
}

.reviews .totalReviewsStats > h3 {
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 22px;
  letter-spacing: 0.04em;
  color: #5e5f60;
}

.reviews .totalReviewsStats > h3 > b {
  font-weight: bold;
}
