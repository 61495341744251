/*
 * @author Oleg Khalidov <brooth@gmail.com>.
 * -----------------------------------------------
 * Freelance software development:
 * Upwork: https://www.upwork.com/freelancers/~01d93e90d5b37c48d2
 */
.form {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.form > form {
  width: 100%;
  padding-top: 0px;
}
.error {
  height: 1.1111rem;
  margin-top: 20px;
  margin-left: 1.1111rem;
  margin-right: 1.1111rem;
  text-align: center;
}
