/*
 * @author Oleg Khalidov <brooth@gmail.com>.
 * -----------------------------------------------
 * Freelance software development:
 * Upwork: https://www.upwork.com/freelancers/~01d93e90d5b37c48d2
 */

.list {
  width: 100%;
  margin-top: 1.9rem;
  margin-left: 1.1rem;
  margin-right: 1.1rem;
}

.list>.row>div {
  padding: 2rem 1.6rem 1.6rem 1.6rem;
  width: 100%;
  height: 100%;
  min-height: 11.1rem;
  border: 1px solid #d1d1d1;
  border-radius: 1.2941rem;
  display: flex;
  flex-direction: column;
  margin-bottom: 1.9rem;
}

.list>.row>div {
  padding: 40px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.list>.row>div>div:first-child {
  display: flex;
  flex-direction: row;
  flex: 3;
  border-right: 1px solid #f4f5f8;
  margin-right: 40px;
}

.list>.row>div>div:first-child>img {
  width: 50px;
  height: 50px;
  border-radius: 50%;
}

.list>.row>div>div:first-child>p {
  padding: 12px 17px 0px 15px;
  font-family: Poppins;
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 26px;
  color: #202020;
}

.list>.row>div>div:last-child {
  display: flex;
  flex-direction: column;
  flex: 10;
}

.list>.row>div>div:last-child>p:first-child {
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 26px;
  letter-spacing: 0.04em;
  color: #777777;
  margin-bottom: 20px;
}

.list>.row>div>div:last-child>p:first-child>b {
  font-weight: 600;
  color: #202020;
}

.list>.row>div>div:last-child>p:first-child>span[class*="alert"] {
  padding: 4px 7px;
  margin-left: 20px;
  background: rgba(234, 67, 53, 0.1);
  border: 1px solid #ea4335;
  box-sizing: border-box;
  border-radius: 12px;
  font-weight: 500;
  font-size: 13px;
  line-height: 24px;
  color: #ea4335;
}

.list>.row>div>div:last-child>div[class="stars"]>svg {
  margin-right: 4px;
  margin-bottom: 6px;
}

.list>.row>div>div:last-child>div[class="stars"]>span {
  margin-left: 8px;
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 24px;
  letter-spacing: 0.04em;
  color: #a9aaaf;
}

.list>.row>div>div:last-child>.comment {
  margin-top: 10px;
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 28px;
  letter-spacing: 0.04em;
  color: #777777;
}

.list>.row>div>div:last-child>.comment>span {
  margin-left: 6px;
  font-family: Poppins;
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 28px;
  letter-spacing: 0.04em;
  color: #1c9afb;
  cursor: pointer;
}

.loadMoreButton {
  margin-top: 20px;
  margin-bottom: 50px;
  padding: 5px 70px;
  width: 8.0588rem;
  display: flex;
  justify-content: center;
  align-items: center;
  background: linear-gradient(302.39deg, #084edd -18.61%, #489ff9 113.13%);
  border: 0px;
  border-radius: 1.7778rem;
  overflow: hidden;
}

.loadMoreButton:hover {
  opacity: 0.9;
}

.loadMoreButton:active {
  opacity: 1;
}

.loadMoreButton>span {
  letter-spacing: 0px;
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 28px;
  letter-spacing: 0.04em;
  color: rgba(255, 255, 255, 1);
  text-transform: uppercase;
}

.replyPanel {
  border-top: 1px solid #d1d1d1;
  margin-top: 20px;
  position: relative;
  display: flex;
  flex-direction: column;
}

.replyPanel>.reviewPanelWrapper {
  margin-top: 32px;
  margin-bottom: 19px;
  display: flex;
  align-items: center;
}

.replyPanel>.reviewPanelWrapper>span {
  margin-left: 10px;
  font-family: Poppins;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 26px;
  color: #202020;
  flex-grow: 1;
}

.replyPanel>p {
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 28px;
  letter-spacing: 0.04em;
  color: #777777;
}

.replyPanel .closeIcon {
  cursor: pointer;
  align-self: flex-start;
  margin-top: 10px;
}

.replyPanel textarea {
  height: 80px;
  width: 100%;
  border: none;
  border-bottom: 1px solid #d1d1d1;
  font-family: 'Circular Std';
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 0.04em;
  color: #333;
}

.replyPanel textarea::-webkit-input-placeholder {
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 20px;
  color: #a9aaaf;
}

.replyPanel textarea:-moz-placeholder {
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 20px;
  color: #a9aaaf;
}

.replyPanel textarea::-moz-placeholder {
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 20px;
  color: #a9aaaf;
}

.replyPanel textarea:-ms-input-placeholder {
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 20px;
  color: #a9aaaf;
}

.replyPanel textarea::placeholder {
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 20px;
  color: #a9aaaf;
}

.replyButtons {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  align-items: center;
}

.bulkReplyButton,
.submitReplyButton,
.replyButton {
  margin-top: 20px;
  margin-bottom: -10px;
  padding: 11px 20px;
  width: min-content;
  display: flex;
  justify-content: center;
  align-items: center;
  background: linear-gradient(302.39deg, #1293f7 -18.61%, #49b0ff 113.13%);
  border: 0px;
  border-radius: 1.7778rem;
  overflow: hidden;
}

.bulkReplyButton {
  background: linear-gradient(302.39deg, #b832a2 -18.61%, #49b0ff 100%);
}

.suggestReplyButton {
  margin-top: 12px;
  margin-bottom: -10px;
  padding: 8px;
  width: min-content;
  display: flex;
  justify-content: center;
  align-items: center;
  background: linear-gradient(302.39deg, #b832a2 -18.61%, #49b0ff 100%);
  /* background: linear-gradient(302.39deg, #1293f7 -18.61%, #49b0ff 113.13%); */
  border: 0px;
  border-radius: 1.7778rem;
  overflow: hidden;
  align-self: flex-end;
}

.bulkReplyButton:hover,
.submitReplyButton:hover,
.replyButton:hover {
  opacity: 0.9;
}

.bulkReplyButton:active,
.submitReplyButton:active,
.replyButton:active {
  opacity: 1;
}

.bulkReplyButton>span,
.submitReplyButton>span,
.replyButton>span {
  letter-spacing: 0px;
  font-family: Circular Std;
  font-style: normal;
  font-weight: normal;
  font-size: 1rem;
  color: rgba(255, 255, 255, 1);
}

.replyButton>span {
  margin-left: 10px;
}

.submitReplyButton {
  margin-top: 13px;
  margin-right: -3px;
  padding: 8px 10px;
}

.replyPanel .actions {
  flex-grow: 1;
  display: flex;
  justify-content: flex-end;
  margin-bottom: 7px;
}

.replyPanel .actions span {
  padding-left: 7px;
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  letter-spacing: 0.04em;
  height: 13px;
  cursor: pointer;
}

.replyPanel .actions>span:first-child {
  padding-right: 7px;
  color: #a9aaaf;
}

.replyPanel .actions>span:last-child {
  border-left: 1px solid #A9AAAF;
  color: #ea4335;
}

.sentimentIcon {
  width: 32px;
  height: 32px;
  align-self: flex-end;
  margin-bottom: -10px;
}

.suggestionBox {
  width: fit-content;
  align-self: center;
  background: rgba(28, 154, 251, 0.1);
  border: 1px solid #1C9AFB;
  box-sizing: border-box;
  border-radius: 20px;
  padding: 20px;
}

.suggestionBox>div[class='header'] {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.suggestionBox>div[class='header']>img {
  width: 34px;
  height: 34px;
  /* background: linear-gradient(271.64deg, #084EDD -21.95%, #6CD0FF 112.75%); */
  background: linear-gradient(302.39deg, #b832a2 -18.61%, #49b0ff 100%);
  border-radius: 99px;
  padding: 5px;
  margin-right: 10px;
}

.suggestionBox>div[class='header']>span {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: #1C9AFB;
}

.suggestionBox>div[class='header']>span>b {
  font-weight: bold;
}

.suggestionBox>p {
  margin: 6px 10px 20px 46px;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 24px;
  color: #777777;
}


.suggestionBox>div[class='footer'] {
  margin-left: 33px;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}

.suggestionBox>div[class='footer']>div {
  margin-left: 10px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 8px 25px;
  height: 38px;
  background: transparent;
  border-radius: 100px;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
  letter-spacing: 0.04em;
  color: #777777;
  border: 1px solid #777777;
}

.suggestionBox>div[class='footer']>div:nth-child(2) {
  border: none;
  background: #1C9AFB;
  color: #FFFFFF;
}

.suggestionBox>div[class='footer']>.suggestRefreshButton {
  margin-top: 1px;
  padding: 5px;
  height: 36px;
  width: 36px;
  align-items: center;
}
