/*
 * @author Oleg Khalidov <brooth@gmail.com>.
 * -----------------------------------------------
 * Freelance software development:
 * Upwork: https://www.upwork.com/freelancers/~01d93e90d5b37c48d2
 */

@import url("https://cdnjs.cloudflare.com/ajax/libs/meyer-reset/2.0/reset.min.css");
* {
  box-sizing: border-box;
}

/*
 * Circular Std
 */
@font-face {
  font-family: "Circular Std";
  src: url("fonts/Circular Std/CircularStd-Book.otf") format("opentype");
}
@font-face {
  font-family: "Circular Std Black";
  src: url("fonts/Circular Std/CircularStd-Black.otf") format("opentype");
}
@font-face {
  font-family: "Circular Std Black-Italic";
  src: url("fonts/Circular Std/CircularStd-BlackItalic.otf") format("opentype");
}
@font-face {
  font-family: "Circular Std Bold";
  src: url("fonts/Circular Std/CircularStd-Bold.otf") format("opentype");
}
@font-face {
  font-family: "Circular Std Bold-Italic";
  src: url("fonts/Circular Std/CircularStd-BoldItalic.otf") format("opentype");
}
@font-face {
  font-family: "Circular Std Book";
  src: url("fonts/Circular Std/CircularStd-Book.otf") format("opentype");
}
@font-face {
  font-family: "Circular Std Book-Italic";
  src: url("fonts/Circular Std/CircularStd-BookItalic.otf") format("opentype");
}
@font-face {
  font-family: "Circular Std Medium";
  src: url("fonts/Circular Std/CircularStd-Medium.otf") format("opentype");
}
@font-face {
  font-family: "Circular Std Medium-Italic";
  src: url("fonts/Circular Std/CircularStd-MediumItalic.otf") format("opentype");
}

/*
 * Poppins Font
 */
@font-face {
  font-family: "Poppins";
  src: url("fonts/Poppins/Poppins-Black.ttf") format("opentype");
  font-weight: 900;
  font-style: normal;
}
@font-face {
  font-family: "Poppins";
  src: url("fonts/Poppins/Poppins-BlackItalic.ttf") format("opentype");
  font-weight: 900;
  font-style: italic;
}
@font-face {
  font-family: "Poppins";
  src: url("fonts/Poppins/Poppins-Bold.ttf") format("opentype");
  font-weight: 700;
  font-style: normal;
}
@font-face {
  font-family: "Poppins";
  src: url("fonts/Poppins/Poppins-BoldItalic.ttf") format("opentype");
  font-weight: 700;
  font-style: italic;
}
@font-face {
  font-family: "Poppins";
  src: url("fonts/Poppins/Poppins-SemiBold.ttf") format("opentype");
  font-weight: 600;
  font-style: normal;
}
@font-face {
  font-family: "Poppins";
  src: url("fonts/Poppins/Poppins-SemiBoldItalic.ttf") format("opentype");
  font-weight: 600;
  font-style: italic;
}
@font-face {
  font-family: "Poppins";
  src: url("fonts/Poppins/Poppins-Medium.ttf") format("opentype");
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: "Poppins";
  src: url("fonts/Poppins/Poppins-MediumItalic.ttf") format("opentype");
  font-weight: 500;
  font-style: italic;
}
@font-face {
  font-family: "Poppin";
  src: url("fonts/Poppins/Poppins-Regular.ttf") format("opentype");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "Poppins";
  src: url("fonts/Poppins/Poppins-Italic.ttf") format("opentype");
  font-weight: normal;
  font-style: italic;
}
@font-face {
  font-family: "Poppin";
  src: url("fonts/Poppins/Poppins-Light.ttf") format("opentype");
  font-weight: 300;
  font-style: normal;
}
@font-face {
  font-family: "Poppins";
  src: url("fonts/Poppins/Poppins-LightItalic.ttf") format("opentype");
  font-weight: 300;
  font-style: italic;
}
@font-face {
  font-family: "Poppin";
  src: url("fonts/Poppins/Poppins-Thin.ttf") format("opentype");
  font-weight: 100;
  font-style: normal;
}
@font-face {
  font-family: "Poppins";
  src: url("fonts/Poppins/Poppins-ThinItalic.ttf") format("opentype");
  font-weight: 100;
  font-style: italic;
}

body {
  margin: 0;
  min-height: 100vh;
  font-family: "Circular Std", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
body:after {
  content: '';
  position: fixed;
  top: -50%;
  right: -50%;
  bottom: -50%;
  left: -50%;
  z-index: -1;
  background: white;
}

#root {
  min-width: 1200px;
}

:root {
  --eerie-black: rgba(32, 32, 32, 1);
  font-size: 3.8mm;
}

svg {
  overflow: visible;
}

.modal-backdrop.show {
  opacity: 0.75 !important;
}
.modal-dialog {
  max-width: 39.6471rem !important;
}
.modal-content {
  background-color: transparent !important;
  border: 0px !important;
  align-items: center;
}

/* Toast */
.Toastify__toast--warning {
  background-color: #f1aa0f;
}
.Toastify__toast--warning > .Toastify__toast-body{
  color: #fff;
}
.Toastify__toast--error > .Toastify__toast-body{
  color: #fdfdfd;
}

.badge {
  font-weight: normal;
}