/*
 * @author Oleg Khalidov <brooth@gmail.com>.
 * -----------------------------------------------
 * Freelance software development:
 * Upwork: https://www.upwork.com/freelancers/~01d93e90d5b37c48d2
 */

.bloc {
  align-items: center;
  background-color: white;
  border-radius: 22px;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}
