/*
 * @author Oleg Khalidov <brooth@gmail.com>.
 * -----------------------------------------------
 * Freelance software development:
 * Upwork: https://www.upwork.com/freelancers/~01d93e90d5b37c48d2
 */

.container {
  width: 100%;
  max-width: 1000px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.container > p {
  margin-left: 20px;
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 22px;
  letter-spacing: 0.04em;
  color: #5e5f60;
  align-self: flex-start;
}
.container > p > b {
  font-weight: bold;
}

.list {
  width: 100%;
  margin-top: 1.9rem;
  margin-left: 1.1rem;
  margin-right: 1.1rem;
}

.list > div {
  margin-top: 3rem;
  background: #ffffff;
  border: 1px solid #e7e7e7;
  box-sizing: border-box;
  border-radius: 20px;
  padding: 30px 30px 15px 30px;
}

.list .header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;
}

.list .header h1 {
  font-family: Poppins;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 34px;
  color: #333333;
}

.list .header h3 {
  cursor: pointer;
  margin-top: 5px;
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 26px;
  letter-spacing: 0.04em;
  color: #585858;
  opacity: 0.7;
}
.list .header h3 > svg {
  margin-left: 9px;
  margin-bottom: 3px;
}

.list .header p {
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 24px;
  text-align: right;
  letter-spacing: 0.04em;
  color: #aaaaaa;
}

.devider {
  margin-top: 15px;
  margin-bottom: 15px;
  width: 100%;
  height: 2px;
}

.improvetags {
  margin-top: 40px;
  display: flex;
  flex-direction: row;
}
.improvetags > h3 {
  padding-top: 3px;
  flex-shrink: 0;
  font-family: Poppins;
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 26px;
  letter-spacing: 0.04em;
  color: #585858;
}

.improvetags > ul {
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
}
.improvetags > ul > li {
  margin-left: 10px;
  margin-bottom: 10px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 4px 16px;
  background: rgba(0, 111, 255, 0.1);
  border-radius: 4px;
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 24px;
  letter-spacing: 0.04em;
  color: #006fff;
}

.comment {
  margin-top: 20px;
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 31px;
  letter-spacing: 0.04em;
  color: #6d6d6d;
}

.delete {
  position: relative;
  left: calc(100% - 22px);
  bottom: -16px;
  width: 55px;
  height: 55px;
  cursor: pointer;
}
