/*
 * @author Oleg Khalidov <brooth@gmail.com>.
 * -----------------------------------------------
 * Freelance software development:
 * Upwork: https://www.upwork.com/freelancers/~01d93e90d5b37c48d2
 */

.list {
  width: 100%;
  margin-top: 1rem;
  justify-content: center;
}

.list > div[class*="row"] > div {
  margin: 0 1rem 1.9rem 1rem;
  padding: 1.2rem 1rem 1rem 1rem;
  width: 100%;
  height: 100%;
  min-height: 7rem;
  min-width: 20rem;
  border: 1px solid #d1d1d1;
  border-radius: 1.2941rem;
  display: flex;
  flex-direction: column;
}

.list > div[class*="row"] > div[class*="active"] {
  cursor: pointer;
}

.list > div[class*="row"] p[class*="title"] {
  text-align: left;
  font-family: Circular Std Bold;
  font-style: normal;
  font-weight: normal;
  font-size: 1.1rem;
  color: rgba(32, 32, 32, 1);
}

.list > div[class*="row"] > div > svg {
  height: 88px;
}

.list > div[class*="row"] .error {
  height: 88px;
}

.list > div[class*="row"] .error > [class="title"] {
  padding-top: 30px;
  text-align: center;
  font-family: Circular Std Book;
  font-style: normal;
  font-weight: normal;
  font-size: 1.2rem;
  color: rgb(134, 36, 36);
}

.list > div[class*="row"] .error > [class="message"] {
  padding-top: 15px;
  text-align: center;
  font-family: Circular Std;
  font-style: normal;
  font-weight: normal;
  font-size: 0.9rem;
  color: rgb(134, 36, 36);
}

.list > div[class*="row"] .content {
  margin-top: 6px;
  height: 80px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.list > div[class*="row"] .content > [class="rating"] {
  display: flex;
  flex-direction: row;
  align-items: center;
  text-align: left;
  font-family: Circular Std Bold;
  font-style: normal;
  font-weight: normal;
  font-size: 1.1rem;
  color: rgba(32, 32, 32, 1);
}

.list > div[class*="row"] .content > [class="rating"] > span {
  padding-left: 5px;
  font-weight: normal;
  font-size: 14px;
}

.list > div[class*="row"] .content > [class="rating"] > span[class~="silent"] {
  color: rgb(143, 143, 143);
  font-family: Circular Std;
}

.list > div[class*="row"] .content > [class*="danger"] {
  background-color: #fb3d42;
}
