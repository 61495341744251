/*
 * @author Oleg Khalidov <brooth@gmail.com>.
 * -----------------------------------------------
 * Freelance software development:
 * Upwork: https://www.upwork.com/freelancers/~01d93e90d5b37c48d2
 */

.modal {
  background-color: transparent;
  border: 0px;
}

.dialog {
  background-color: white;
  padding: 2rem 1.6rem 1.6rem 1.6rem;
  width: auto;
  min-height: 11.1rem;
  border: 1px solid #d1d1d1;
  border-radius: 1.2941rem;
  display: flex;
  flex-direction: column;
  width: 23.5294rem;
}

.dialog > .content {
  flex: 4;
  display: flex;
  flex-direction: column;
}
.dialog > .content [class*="title"] {
  text-align: left;
  font-family: Circular Std Bold;
  font-style: normal;
  font-weight: normal;
  font-size: 1.4118rem;
  color: rgba(32, 32, 32, 1);
}
.dialog > .content [class*="message"] {
  text-align: left;
  font-family: Circular Std;
  font-style: normal;
  font-weight: normal;
  font-size: 1.1rem;
  color: rgba(32, 32, 32, 1);
  margin-top: 10px;
}
.dialog > .content [class*="error"] {
  text-align: left;
  font-family: Circular Std;
  font-style: normal;
  font-weight: normal;
  font-size: 0.9rem;
  color: #df2c2c;
  margin-top: 10px;
}
.dialog > .content [class*="icon"] {
  margin: 0.13rem 0.7rem 0px 0.3rem;
}

.dialog > .buttons {
  flex: 1;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  height: 45px;
}
.dialog > .buttons span {
  font-size: 0.9rem;
}

.dialog > .buttons > div {
  border: 1px solid #d1d1d1;
  flex: 1;
}
.dialog > .buttons > div:nth-child(1) {
  margin-right: 0.7rem;
}
.dialog > .buttons > div:nth-child(2) {
  margin-left: 0.7rem;
}

.dialog > .buttons [class*="danger"]  {
  color: #df2c2c;
  border: 1px solid #f8cece;
  background: linear-gradient(302.39deg, #fceded -18.61%, rgb(250, 212, 212) 113.13%);
}
