/*
 * @author Oleg Khalidov <brooth@gmail.com>.
 * -----------------------------------------------
 * Freelance software development:
 * Upwork: https://www.upwork.com/freelancers/~01d93e90d5b37c48d2
 */
.container {
  width: 280px;
}

.header {
  display: flex;
  justify-content: space-between;
  margin-bottom: 0.6111rem;
}

.label {
  opacity: 0.7;
  overflow: visible;
  white-space: nowrap;
  text-align: left;
  font-family: Poppins;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 26px;
  letter-spacing: 0.04em;
  color: #202020;
}

.error {
  text-align: right;
  font-family: Circular Std Book;
  font-size: 0.8889rem;
  color: rgb(212, 40, 40);
}

.control {
  height: 3.4rem;
  width: 100%;
  background: none;
  background-color: #f6f6f6;
  border: 1px solid #d1d1d1;
  border-radius: 0.6667rem;
  white-space: nowrap;
  text-align: left;
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 24px;
  letter-spacing: 0.02em;
  color: rgba(32, 32, 32, 1);
  box-sizing: border-box;
  outline: none;
}
.control:invalid {
  color: #7e868d;
}

.control div[class*="-control"] {
  border: none;
  padding: 6px;
  background: none;
  outline: none;
}

.control div[class*="-control"] span[class*="-indicatorSeparator"] {
  display: none;
}

.control div[class*="-menu"] {
  border: 1px solid #d1d1d1;
  border-radius: 0.6667rem;
}
.control div[class*="-menu"] div[class*="-option"] {
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 24px;
  letter-spacing: 0.02em;
  /* color: #777777; */
}
