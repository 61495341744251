/*
 * @author Oleg Khalidov <brooth@gmail.com>.
 * -----------------------------------------------
 * Freelance software development:
 * Upwork: https://www.upwork.com/freelancers/~01d93e90d5b37c48d2
 */

.locationBloc {
  width: 39.6471rem;
  padding: 3.3rem 2.7rem 2.7rem 2.7rem;
}

.locationBloc .header {
  width: 100%;
  height: 1.8824rem;
  display: inline-flex;
  justify-content: space-between;
  align-items: center;
}

.locationBloc .header>span {
  font-family: Circular Std;
  font-style: normal;
  font-weight: normal;
  font-size: 1.8824rem;
  color: rgba(32, 32, 32, 1);
}

.form {
  padding-top: 1.7647rem;
}

.form>form>* {
  margin-top: 1.5rem;
}

.form>form [class*="rbt"] {
  border: none;
}

.form .fieldWithRightIcon>input {
  padding-right: 3.2rem;
}

.form .resetLinkIcon {
  position: absolute;
  right: 3.8rem;
  margin-top: -2.1rem;
  color: #084edd;
}

.form .resetLinkIcon[data-loading=true] {
  -webkit-animation: spin 1s linear infinite;
  -moz-animation: spin 1s linear infinite;
  animation: spin 1s linear infinite;
}

@-moz-keyframes spin {
  100% {
    -moz-transform: rotate(360deg);
  }
}

@-webkit-keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.form .copyReviewLinkIcon {
  position: absolute;
  right: 3.8rem;
  margin-top: -2.36rem;
}

.form>form>.taglist>div[class*="react-tagsinput"] {
  margin-top: 0.6rem;
  padding: 0.7rem 1rem 0.4rem 1rem;
  width: 100%;
  background-color: #f6f6f6;
  border: 0px;
  border-radius: 0.6667rem;
}

.form>form>.taglist>div[class*="react-tagsinput"] input[class="react-tagsinput-input"] {
  width: 110px;
}

.form>form>.taglist>div[class*="react-tagsinput"] span[class="react-tagsinput-tag"] {
  white-space: nowrap;
  text-align: left;
  font-family: Circular Std;
  font-style: normal;
  font-weight: normal;
  font-size: 1rem;
  color: white;
  background-color: rgb(53, 100, 187);
  border: 1px solid rgb(30, 88, 128);
  border-radius: 5px;
}

.form>form>.taglist>div[class*="react-tagsinput"] a[class="react-tagsinput-remove"] {
  color: white;
}

.form>form>.taglist>span[class="label"] {
  opacity: 0.7;
  overflow: visible;
  white-space: nowrap;
  text-align: left;
  font-family: Circular Std Book;
  font-weight: normal;
  font-size: 0.8889rem;
  color: rgba(32, 32, 32, 1);
}

.form>form div[class="checkboxes"] {
  padding-top: 5px;
  display: flex;
  justify-content: flex-end;
  position: absolute;
  right: 42px;
}

.form>form div[class="checkboxes"]>label {
  padding-left: 5px;
  font-family: Circular Std Book;
  font-weight: normal;
  font-size: 0.8889rem;
  color: #666;
}

.form>form div[class="checkboxes"]>label>input {
  margin: 5px;
}


.form>form>.taglist[id="blacklist"]>div[class*="react-tagsinput"] input[class="react-tagsinput-input"] {
  width: 165px;
}

.form>form>.taglist[id="blacklist"]>div[class*="react-tagsinput"] span[class="react-tagsinput-tag"] {
  background-color: rgb(43, 41, 41);
  border: 1px solid black;
}

.form>form>.gConnectPanel {
  margin-top: 2.5rem;
  border: 1px solid #d7d7d7;
  border-radius: 0.7059rem;
  padding: 0.8824rem 1.1rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.gConnectPanel>.gLogoBorder {
  margin-right: 15px;
  background-color: #e8e8e8;
  border-radius: 999px;
  padding: 7px 7px 5px 8px;
}

.gConnectPanel>.gLogoBorder>img {
  width: 18px;
  height: 18px;
}

.gConnectPanel [class*="error"] {
  line-height: 2rem;
}

.gConnectPanel h5 {
  font-family: Circular Std Medium;
  font-size: 1.0588rem;
  color: rgba(32, 32, 32, 1);
}

.gConnectPanel h6 {
  font-family: Circular Std Book;
  font-size: 0.8824rem;
  color: rgba(32, 32, 32, 1);
  padding-top: 0.5882rem;
}

.form>form .gConnectButton {
  margin-left: 15px;
  padding: 14px 20px;
  background: linear-gradient(302.39deg, #084edd -18.61%, #489ff9 113.13%);
}

.form>form .gDisconnectButton {
  margin-left: 15px;
  padding: 14px 20px;
  background: #e1e9fb;
}

.form>form>.saveButton {
  margin-top: 28px;
  height: 45px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: linear-gradient(302.39deg, #084edd -18.61%, #489ff9 113.13%);
  border: 0px;
  border-radius: 1.7778rem;
  overflow: hidden;
}

.gConnectButton>span,
.saveButton>span {
  color: white;
}

.form>form .gDisconnectButton>span {
  color: #084edd;
}

.imagePicker {
  margin-top: 40px;
  margin-bottom: -50px;
  width: 100%;
  height: 150px;
  /* background-color: khaki; */
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px dashed #ddd;
  border-radius: 10px;
}

.imagePicker * {
  max-height: 150px;
  position: absolute;
}

.imagePicker img {
  z-index: 1;
}

.imagePicker input {
  z-index: 100;
  width: 100%;
  height: 100%;
  opacity: 0;
  cursor: pointer;
}

.imagePicker input::file-selector-button {
  display: none;
}

.imagePicker>div {
  width: 120px;
  height: 120px;
  color: #888;
  display: flex;
  justify-content: center;
  align-items: center;
}

.locationPicker {
  margin-top: 50px;
  max-height: 80vh;
  width: 100%;
  height: 34rem;
  border: 1px solid #eee;
  border-radius: 10px;
  overflow-x: scroll;
}

.locationPickerItem {
  width: 34.3rem;
  padding: 15px 20px;
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
  border-radius: 10px;
}

.locationPickerItem:hover {
  background: #489ff955;
}

.locationPickerItem svg {
  margin-right: 15px;
}

.locationPickerItem>[class*="content"] {
  display: flex;
  flex-direction: column;
}

.locationPickerItem>[class*="content"]>h5 {
  padding-bottom: 6px;
  font-family: Circular Std Medium;
  font-size: 1rem;
  color: rgba(32, 32, 32, 1);
}

.locationPickerItem>[class*="content"]>p {
  font-family: Circular Std Medium;
  font-size: 0.9rem;
  color: rgb(80, 80, 80);
}

@keyframes waves {
  0% {
    background-position: 0vw 0;
  }

  100% {
    background-position: 1000000vw 0;
  }
}